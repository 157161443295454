<template>
  <div class="disclaimer">
    <div class="vl"></div>

    <div
      class="disclaimer-text"
      v-if="
        productType === 'beanie' ||
        productType === 'scarf' ||
        productType === 'truckercap'
      "
    >
      <div v-if="infoPdfLink">
        <a :href="`${infoPdfLink}`" target="_blank"
          >Not Sure About Your Logo?</a
        >
      </div>
      <br />
      <div>
        Please note, the default logo method is embroidery. Some logos,
        depending on their complexity and size, cannot be embroidered and may
        require adjustments to improve the quality to get the best product! One
        of our Account Managers will be in contact with you to discuss the
        options.
      </div>
      <br />
      <div>
        *You can upload your high-quality vector design file (.PDF, .ai, or
        .eps) at the checkout.
      </div>
    </div>

    <div class="disclaimer-text" v-if="productType === 'crewsock'">
      <div v-if="infoPdfLink">
        <a :href="`${infoPdfLink}`" target="_blank"
          >Not Sure About Your Logo?</a
        >
      </div>
      <br />
      <div>
        Please note, the default logo method is woven. Some logos, depending on
        their complexity and size, cannot be achieved as a woven logo, and may
        require adjustments to improve the quality to get the best product! One
        of our Account Managers will be in contact with you to discuss the
        options.
      </div>
      <br />
      <div>
        *You can upload your high-quality vector design file (.PDF, .ai, or
        .eps) at the checkout.
      </div>
    </div>

    <div
      class="disclaimer-text"
      v-if="
        productType === 'cuthoodie' ||
        productType === 'subhoodie' ||
        productType === 't_shirt' ||
        productType === 'singlet' ||
        productType === 'longsleeve' ||
        productType === 'basketball_shorts' ||
        productType === 'basketball_jerseys'
      "
    >
      <div v-if="infoPdfLink">
        <a :href="`${infoPdfLink}`" target="_blank"
          >Not Sure About Your Logo?</a
        >
      </div>
      <br />
      <div>
        Please note, some logos, depending on their complexity and size, may
        lose some of their detailing and may require adjustments to improve the
        quality to get the best product! One of our Account Managers will be in
        contact with you to discuss the options.
      </div>
      <br />
      <div>
        *You can upload your high-quality vector design file (.PDF, .ai, or
        .eps) at the checkout.
      </div>
    </div>
  </div>
</template>

<script>
import { computed, inject } from "vue";
import useThemeSettings from "@/hooks/themeSettings";

export default {
  name: "DisclaimerText",
  setup() {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const productType = inject("activeProductType");
    const productCategoryPdfs = inject("productCategoryPdfs");
    const infoPdfLink = productCategoryPdfs.value["logo-info"];

    // switch (productType) {
    //   case "beanie":
    //   case "scarf":
    //   case "cuthoodie":
    //   case "truckercap":
    //     infoPdfLink = baseUrl.value + "/DYO_LOGO_FAQ.pdf";
    //     break;
    //   default:
    //     infoPdfLink = baseUrl.value + "/DYO_300DPI_Text.pdf";
    // }

    return {
      productType,
      infoPdfLink,
      accentColor,
    };
  },
};
</script>

<style lang="scss" scoped>
.disclaimer {
  .vl {
    --accent-color: v-bind(accentColor);
  }
}

.disclaimer {
  display: flex;
  padding: 20px 30px 0;
  text-align: left;
  a {
    color: #808080;
  }
  a:hover {
    color: #666666;
  }
  .disclaimer-text {
    padding: 10px 0;
    color: gray;
  }
  .vl {
    border-left: 2px solid var(--accent-color);
    margin-right: 10px;
  }
}
@media only screen and (max-height: 768px) {
  .disclaimer {
    padding: 10px 30px;
  }
}
</style>
