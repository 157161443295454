
import {
  defineComponent,
  computed,
  provide,
  ref,
  Ref,
  onBeforeUnmount,
  onBeforeMount,
} from "vue";
import { useRoute } from "vue-router";
import { useProductsStore } from "@/stores/ProductsStore";
import { useCartStore } from "@/stores/CartStore";
import * as sidebar from "@/logic/sidebarNavigation";
import * as meta from "@/logic/productMeta";
import * as price from "@/logic/price";

import ProgressSpinner from "primevue/progressspinner";

import {
  ITextData,
  IAddon,
  IPlayerData,
  ISizeData,
  ICheetaLogoMeta,
  IPlayerDataProductDetails,
  IProduct,
} from "@/interfaces";

import MobileHeader from "../components/Header.vue";
import Header from "../components/ConstructorHeader.vue";
import ConstructorPanel from "../components/Constructor/ConstructorPanel.vue";
import PreviewPanel from "../components/Constructor/PreviewPanel.vue";
import PreviewPanelMobile from "../components/Constructor/PreviewPanelMobile.vue";
import useProductSettings from "@/hooks/productSettings";
import { API } from "@/api";
import useFlashNotifications from "@/hooks/flashNotifications";
import Toast from "primevue/toast";
import { sidebarOptions } from "@/logic/sidebarNavigation";
import { useCompanyStore } from "@/stores/CompanyStore";

export default defineComponent({
  name: "ConstructorView",
  components: {
    Header,
    ProgressSpinner,
    MobileHeader,
    ConstructorPanel,
    PreviewPanel,
    PreviewPanelMobile,
    Toast,
  },
  setup() {
    const { showSuccess, showError } = useFlashNotifications();
    const store = useProductsStore();
    const cart = useCartStore();
    const route = useRoute();
    const api = new API();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }

    let categoryName: Ref<string> = ref("");
    const getCategoryName = computed(function () {
      return categoryName;
    });
    const categories = store.getProductCategories;
    const viewportWidth = ref(window.innerWidth);
    window.addEventListener("resize", () => {
      viewportWidth.value = window.innerWidth;
    });
    const windowWidthDetection = computed(() => {
      if (viewportWidth.value <= 970) return "Mobile";
      return "Desktop";
    });
    const activeProduct: Ref<IProduct> = cart.getPendingItem;
    const loading = ref(false);
    let inittialProductAlias = ref("");
    onBeforeMount(async () => {
      loading.value = true;

      await store.updateProductsInfo(companyInfo.id);
      if (route.params.product && route.query.id) {
        // IF WE PASS UNIQUE ID TO THE COMPONENT FETCH CUSTOM PRODUCT AND EDIT IT
        const id = route.query.id as string;
        const product = cart.getProductById(id);
        inittialProductAlias.value = product.alias;
        setPendingItem(product);
      } else if (route.params.product) {
        let newActiveProduct = null;

        // IF ALIAS ID IS PROVIDED IN THE URL
        // DESIGN LOGIC
        const designId =
          route.params && route.params.hash ? route.params.hash : null;

        let settings: any = {};
        if (designId) {
          try {
            let response = await api.getProductDesign(
              companyInfo.id,
              designId as string
            );
            settings = response.data;

            if (settings.alias) {
              newActiveProduct = store.getItemByAlias(settings.alias);
              const useProductSettingsHook = useProductSettings();
              useProductSettingsHook.prefill(newActiveProduct, settings.config);
              setPendingItem(newActiveProduct);
            }
          } catch (e) {
            console.log("Couldn't retrieve design settings");
          }
        }

        if (!newActiveProduct) {
          // IF NOT, USE THE FIRST OF THE PRODUCT TYPE TEMPALTES
          inittialProductAlias.value = getProductTemplates.value[0].alias;
          newActiveProduct = store.getItemByAlias(inittialProductAlias.value);
          setPendingItem(newActiveProduct);
        }
      }

      if (!activeProduct) return;
      sidebar.setSidebarOptions(activeProduct.value.type);
      await meta.getMetaForCurrentProduct(activeProduct.value.type);
      if (
        !activeProduct.value.quantity ||
        activeProduct.value.quantity < activeProduct.value.minQty
      ) {
        onQtyChange(activeProduct.value.minQty);
      }

      // if (activeProduct) {
      const productCategoryAlias = route.params.product;
      const searchResult = categories.find(function (el) {
        return el.alias === productCategoryAlias;
      });

      if (searchResult) {
        categoryName.value = searchResult.h1
          ? searchResult.h1
          : searchResult.name;
      }

      document.title =
        searchResult && searchResult.title
          ? searchResult.title
          : "Cheeta Teamwear";

      if (searchResult && searchResult.description) {
        const descriptionMetaTag = document.querySelector(
          "meta[name='description']"
        );
        if (descriptionMetaTag) {
          descriptionMetaTag.setAttribute("content", searchResult.description);
        }
      }
      // }
      loading.value = false;
    });
    onBeforeUnmount(() => {
      cart.clearEditedValuesBuffer();
    });
    const getProductTemplates = computed(() => {
      const allProductTemplates = store.getProductTemplates(
        route.params.product as string
      );
      return allProductTemplates;
    });

    // METHODS
    const setPendingItem = (activeProduct: IProduct) => {
      cart.setPendingItem(activeProduct as IProduct);
    };
    const onQtyChange = (qty: number) => {
      if (qty < activeProduct.value.minQty || !qty) {
        activeProduct.value.quantity = activeProduct.value.minQty;
      } else {
        activeProduct.value.quantity = qty;
      }
      price.fetchActualPrice(
        activeProduct.value.databaseId,
        "",
        activeProduct.value.quantity
      );
    };
    const changeProductTemplate = async (alias: string) => {
      const newProduct = store.getItemByAlias(alias);
      activeProduct.value = newProduct;
      onQtyChange(activeProduct.value.minQty);
      setPendingItem(activeProduct.value);
    };
    const changeProductColor = (data: {
      type: string;
      color: { value: string; name: string };
    }) => {
      cart.changeColor(data.type, data.color);
    };
    const changeProductLogo = (data: { path: string; size: number }) => {
      cart.setLogo(data);
    };
    const changeDetails = (data: IPlayerDataProductDetails) => {
      cart.changeDetailsOnMultiplePlayersProduct(data);
    };
    const addOneOfTheLogos = (data: {
      path: string;
      size: number;
      position: string;
      offsetX: number;
      offsetY: number;
    }) => {
      cart.setOneOfLogos(data);
    };
    const removeOneOfTheLogos = (position: string) => {
      cart.removeOneOfLogos(position);
    };
    const changeProductText = (data: ITextData) => {
      cart.changeText(data);
    };
    const changeAddons = (addon: IAddon) => {
      const selectedAddons = [...activeProduct.value.addons];
      const index = activeProduct.value.addons.findIndex(
        (el) => el.id === addon.id
      );
      if (index === -1) {
        selectedAddons.push(addon);
      } else if (index >= 0) {
        selectedAddons.splice(index, 1);
      }
      cart.changeAddons(selectedAddons);
    };
    const changeCheetaLogo = (data: ICheetaLogoMeta) => {
      cart.changeCheetaLogoData(data);
    };
    const changePlayerDetails = (detailsArray: IPlayerData[]) => {
      cart.setPlayerDetails(detailsArray);
    };
    const changeSizes = (data: ISizeData[]) => {
      cart.setProductSizes(data);
    };
    const saveDesign = async (
      activeProduct: any,
      userData: any,
      v$: any,
      loadingDesignRequest: Ref<boolean>,
      openSaveDesignDialog: Ref<boolean>,
      onSuccess: (
        userData: any,
        v$: any,
        loadingDesignRequest: Ref<boolean>,
        openSaveDesignDialog: Ref<boolean>
      ) => void
    ) => {
      let formData = new FormData();
      let config = {
        text: activeProduct.value.text,
        colors: activeProduct.value.colors,
      };

      formData.append("config", JSON.stringify(config));
      formData.append("email", userData.value.email);
      formData.append("name", userData.value.name);
      formData.append("lastName", userData.value.lastName);

      if (activeProduct.value.logo) {
        if (activeProduct.value.logo.path) {
          // single logo mode
          formData.append("logo[size]", activeProduct.value.logo.size);

          const base64Data = activeProduct.value.logo.path;
          const base64Response = await fetch(base64Data);
          const blob = await base64Response.blob();

          formData.append("logo[image]", new File([blob], "logo.svg"));
        } else {
          for (const property in activeProduct.value.logo) {
            let logoObj = activeProduct.value.logo[`${property}`];

            if (logoObj) {
              formData.append(`logos[${property}][size]`, logoObj.size);
              formData.append(
                `logos[${property}][offsetY]`,
                logoObj.offsetY ?? null
              );
              const base64Data = logoObj.path;
              const base64Response = await fetch(base64Data);
              const blob = await base64Response.blob();

              formData.append(
                `logos[${property}][image]`,
                new File([blob], property + ".svg")
              );
            }
          }
        }
      }

      await api
        .saveProductDesign(
          companyInfo.id,
          activeProduct.value.databaseId,
          formData
        )
        .then(() => {
          onSuccess(userData, v$, loadingDesignRequest, openSaveDesignDialog);
          showSuccess("Design is saved");
        })
        .catch(() => {
          showError("Something went wrong. If the issue repeats contact us.");
        })
        .then(() => {
          loadingDesignRequest.value = false;
        });
    };

    // DATA TO INJECT
    provide(
      "productCategoryPdfs",
      computed(() => store.getProductCategoryPdfs)
    );
    provide(
      "productCategoryName",
      computed(() => store.getProductCategoryName)
    );
    provide(
      "availableSizes",
      computed(() => store.getSizeOptions)
    );
    provide(
      "productSettings",
      computed(() => store.getProductSettings)
    );
    provide(
      "minimumQuantity",
      computed(() => activeProduct.value.minQty)
    );
    provide(
      "minimumPrice",
      computed(() => activeProduct.value.price)
    );
    provide(
      "priceLoading",
      computed(() => price.priceLoading.value)
    );
    provide(
      "availableAddons",
      computed(() => cart.getAvailableAddons.value)
    );
    provide(
      "selectedAddons",
      computed(() => activeProduct.value.addons)
    );
    provide(
      "currentTab",
      computed(() => sidebar.currentTab.value)
    );
    provide(
      "activeSidebarOption",
      computed(() => sidebar.getActiveSidebarOption())
    );

    provide("sidebarOptions", sidebar.sidebarOptions);
    provide("activeProduct", activeProduct);
    const colorOptions = store.getColorOptions;
    provide("colorOptions", colorOptions);
    const colorOptionsForHats = store.getColorOptionsForHats;
    provide("colorOptionsForHats", colorOptionsForHats);
    provide(
      "changableColors",
      computed(() => cart.getChangableColors)
    );
    provide(
      "productDetails",
      computed(() => activeProduct.value.details)
    );
    provide(
      "productAlias",
      computed(() => activeProduct.value.alias)
    );
    provide(
      "textData",
      computed(() => cart.getTextData)
    );
    provide(
      "playersData",
      computed(() => activeProduct.value.playersDetails)
    );
    provide(
      "sizesData",
      computed(() => activeProduct.value.sizes)
    );
    provide(
      "logoImage",
      computed(() => activeProduct.value.logo)
    );
    provide(
      "cheetaLogoData",
      computed(() => activeProduct.value.cheetaLogo)
    );
    provide("activeProductType", route.params.product);
    const productTemplates = computed(() => getProductTemplates);
    provide("productTemplates", productTemplates.value);
    /////////////////////////////////

    return {
      categoryName,

      sidebar,
      windowWidthDetection,

      saveDesign,
      loading,
      changeDetails,
      onQtyChange,
      changeAddons,
      activeProduct,
      setPendingItem,
      addOneOfTheLogos,
      changeSizes,
      changeCheetaLogo,
      changeProductLogo,
      changeProductText,
      changeProductColor,
      changePlayerDetails,
      removeOneOfTheLogos,
      changeProductTemplate,
    };
  },
});
