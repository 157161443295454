
import { defineComponent, inject, Ref } from "vue";
import Sidebar from "./ToolsSidebar.vue";
import {
  IAddon,
  ICheetaLogoMeta,
  IPlayerData,
  IPlayerDataProductDetails,
  IProduct,
  ISizeData,
  ITextData,
  SidebarOption,
} from "@/interfaces";
import Button from "primevue/button";

import templateTab from "./ToolsTabs/Template.vue";
import colorTab from "./ToolsTabs/Color.vue";
import textTab from "./ToolsTabs/Text.vue";
import logoTab from "./ToolsTabs/Logo.vue";
import multipleLogoTab from "./ToolsTabs/MultipleLogo.vue";
import extrasTab from "./ToolsTabs/Extras.vue";
import nameSizeTab from "./ToolsTabs/NameSize.vue";
import sizeTab from "./ToolsTabs/Size.vue";
import useCart from "@/hooks/cart";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "ConstructorPanel",
  emits: [
    "sizeChanged",
    "changeTab",
    "logoChanged",
    "textChanged",
    "detailsChanged",
    "colorChanged",
    "addonsChanged",
    "quantityChanged",
    "switchToNextTab",
    "templateChanged",
    "setOneOfTheLogos",
    "cheetaLogoChanged",
    "removeOneOfTheLogos",
    "playerDetailsChanged",
  ],
  components: {
    Button,
    Sidebar,
    textTab,
    sizeTab,
    logoTab,
    colorTab,
    extrasTab,
    templateTab,
    nameSizeTab,
    multipleLogoTab,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const currentTab: Ref<{ id: string }> | undefined = inject("currentTab");
    if (!currentTab) throw new Error("Current tab is undefined");

    const activeProduct: Ref<IProduct> | undefined = inject("activeProduct");
    if (!activeProduct) throw new Error("activeProduct is undefined");

    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );

    const { submitProduct } = useCart(activeProduct);

    const changeTab = (event: SidebarOption) => {
      emit("changeTab", event);
    };
    const templateChanged = (eventData: string) => {
      emit("templateChanged", eventData);
    };
    const colorChanged = (eventData: { type: string; color: string }) => {
      emit("colorChanged", eventData);
    };
    const logoChanged = (eventData: { path: string; size: number }) => {
      emit("logoChanged", eventData);
    };
    const setOneOfTheLogos = (eventData: {
      path: string;
      size: number;
      position: string;
    }) => {
      emit("setOneOfTheLogos", eventData);
    };
    const removeOneOfTheLogos = (position: string) => {
      emit("removeOneOfTheLogos", position);
    };
    const textChanged = (eventData: ITextData) => {
      emit("textChanged", eventData);
    };
    const addonsChanged = (eventData: Array<IAddon>) => {
      emit("addonsChanged", eventData);
    };
    const swithTab = (integer: number) => {
      emit("switchToNextTab", integer);
    };
    const cheetaLogoChanged = (data: ICheetaLogoMeta) => {
      emit("cheetaLogoChanged", data);
    };
    const changePlayersDetails = (detailsArray: Array<IPlayerData>) => {
      emit("playerDetailsChanged", detailsArray);
    };
    const qtyChanged = (quantity: number) => {
      emit("quantityChanged", quantity);
    };
    const onDetailsChange = (data: IPlayerDataProductDetails) => {
      emit("detailsChanged", data);
    };
    const onSizeChange = (sizes: ISizeData[]) => {
      emit("sizeChanged", sizes);
    };

    return {
      accentColor,
      accentColorHover,
      submitProduct,
      swithTab,
      onSizeChange,
      changeTab,
      qtyChanged,
      currentTab,
      logoChanged,
      textChanged,
      colorChanged,
      onDetailsChange,
      addonsChanged,
      templateChanged,
      setOneOfTheLogos,
      cheetaLogoChanged,
      removeOneOfTheLogos,
      changePlayersDetails,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "ecfd1cf0": (_ctx.accentColor),
  "0209c6d8": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__