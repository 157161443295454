<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 15 311"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-miterlimit: 1.5;
    "
  >
    <g transform="matrix(1,0,0,1,-34,-354)">
      <g id="step-indicator">
        <g
          id="step-1"
          transform="matrix(1.18994,0,0,1.18994,-12.5087,-72.4994)"
        >
          <circle
            cx="45.499"
            cy="364.528"
            r="4.642"
            style="fill: none; stroke: #fff; stroke-width: 2.63px"
          />
          <circle cx="45.499" cy="364.528" r="4.642" style="fill: #f47433" />
        </g>
        <g id="step-2" transform="matrix(1.18994,0,0,1.18994,-12.5087,22.9476)">
          <circle
            cx="45.499"
            cy="364.528"
            r="4.642"
            style="fill: #fff; stroke: #f47433; stroke-width: 2.63px"
          />
        </g>
        <g id="step-4" transform="matrix(1.18994,0,0,1.18994,-12.5087,124.2)">
          <circle
            cx="45.499"
            cy="364.528"
            r="4.642"
            style="fill: #fff; stroke: #f47433; stroke-width: 2.63px"
          />
        </g>
        <g id="step-3" transform="matrix(1.18994,0,0,1.18994,-12.5087,223.395)">
          <circle
            cx="45.499"
            cy="364.528"
            r="4.642"
            style="fill: #fff; stroke: #f47433; stroke-width: 2.63px"
          />
        </g>
        <g
          id="step1-line-active"
          transform="matrix(0.432502,0,0,1.0105,22.6763,-4.55481)"
        >
          <path
            d="M48.827,374.509C48.827,373.329 46.587,372.37 43.829,372.37C41.07,372.37 38.831,373.329 38.831,374.509L38.831,444.209C38.831,445.39 41.07,446.348 43.829,446.348C46.587,446.348 48.827,445.39 48.827,444.209L48.827,374.509Z"
            style="fill: none"
          />
        </g>
        <g
          id="step-2-line-next"
          transform="matrix(0.432502,0,0,0.0965956,22.7168,433.551)"
        >
          <path
            d="M48.827,394.748L48.827,423.971C48.827,436.321 46.587,446.348 43.829,446.348C41.07,446.348 38.831,436.321 38.831,423.971L38.831,394.748C38.831,382.397 41.07,372.37 43.829,372.37C46.587,372.37 48.827,382.397 48.827,394.748ZM48.827,513.292L48.827,542.514C48.827,554.865 46.587,564.892 43.829,564.892C41.07,564.892 38.831,554.865 38.831,542.514L38.831,513.292C38.831,500.941 41.07,490.914 43.829,490.914C46.587,490.914 48.827,500.941 48.827,513.292ZM48.827,632.37L48.827,661.592C48.827,673.943 46.587,683.97 43.829,683.97C41.07,683.97 38.831,673.943 38.831,661.592L38.831,632.37C38.831,620.019 41.07,609.992 43.829,609.992C46.587,609.992 48.827,620.019 48.827,632.37ZM48.827,754.185L48.827,783.408C48.827,795.759 46.587,805.786 43.829,805.786C41.07,805.786 38.831,795.759 38.831,783.408L38.831,754.185C38.831,741.835 41.07,731.808 43.829,731.808C46.587,731.808 48.827,741.835 48.827,754.185ZM48.827,866.611L48.827,895.834C48.827,908.184 46.587,918.211 43.829,918.211C41.07,918.211 38.831,908.184 38.831,895.834L38.831,866.611C38.831,854.26 41.07,844.234 43.829,844.234C46.587,844.234 48.827,854.26 48.827,866.611ZM48.827,988.427L48.827,1017.65C48.827,1030 46.587,1040.03 43.829,1040.03C41.07,1040.03 38.831,1030 38.831,1017.65L38.831,988.427C38.831,976.076 41.07,966.049 43.829,966.049C46.587,966.049 48.827,976.076 48.827,988.427ZM48.827,1106.42L48.827,1135.64C48.827,1147.99 46.587,1158.02 43.829,1158.02C41.07,1158.02 38.831,1147.99 38.831,1135.64L38.831,1106.42C38.831,1094.07 41.07,1084.04 43.829,1084.04C46.587,1084.04 48.827,1094.07 48.827,1106.42Z"
            style="fill: #f47433"
          />
        </g>
        <g
          id="step-2-line-active"
          transform="matrix(0.432502,0,0,1.0105,22.6763,94.256)"
        >
          <path
            d="M48.827,374.509C48.827,373.329 46.587,372.37 43.829,372.37C41.07,372.37 38.831,373.329 38.831,374.509L38.831,444.209C38.831,445.39 41.07,446.348 43.829,446.348C46.587,446.348 48.827,445.39 48.827,444.209L48.827,374.509Z"
            :style="{
              fill: secondStep ? '#f47433' : 'none',
            }"
          />
        </g>
        <g
          id="step-3-line-next"
          transform="matrix(0.432502,0,0,0.0965956,22.6589,532.043)"
        >
          <path
            d="M48.827,394.748L48.827,423.971C48.827,436.321 46.587,446.348 43.829,446.348C41.07,446.348 38.831,436.321 38.831,423.971L38.831,394.748C38.831,382.397 41.07,372.37 43.829,372.37C46.587,372.37 48.827,382.397 48.827,394.748ZM48.827,513.292L48.827,542.514C48.827,554.865 46.587,564.892 43.829,564.892C41.07,564.892 38.831,554.865 38.831,542.514L38.831,513.292C38.831,500.941 41.07,490.914 43.829,490.914C46.587,490.914 48.827,500.941 48.827,513.292ZM48.827,632.37L48.827,661.592C48.827,673.943 46.587,683.97 43.829,683.97C41.07,683.97 38.831,673.943 38.831,661.592L38.831,632.37C38.831,620.019 41.07,609.992 43.829,609.992C46.587,609.992 48.827,620.019 48.827,632.37ZM48.827,754.185L48.827,783.408C48.827,795.759 46.587,805.786 43.829,805.786C41.07,805.786 38.831,795.759 38.831,783.408L38.831,754.185C38.831,741.835 41.07,731.808 43.829,731.808C46.587,731.808 48.827,741.835 48.827,754.185ZM48.827,866.611L48.827,895.834C48.827,908.184 46.587,918.211 43.829,918.211C41.07,918.211 38.831,908.184 38.831,895.834L38.831,866.611C38.831,854.26 41.07,844.234 43.829,844.234C46.587,844.234 48.827,854.26 48.827,866.611ZM48.827,988.427L48.827,1017.65C48.827,1030 46.587,1040.03 43.829,1040.03C41.07,1040.03 38.831,1030 38.831,1017.65L38.831,988.427C38.831,976.076 41.07,966.049 43.829,966.049C46.587,966.049 48.827,976.076 48.827,988.427ZM48.827,1106.42L48.827,1135.64C48.827,1147.99 46.587,1158.02 43.829,1158.02C41.07,1158.02 38.831,1147.99 38.831,1135.64L38.831,1106.42C38.831,1094.07 41.07,1084.04 43.829,1084.04C46.587,1084.04 48.827,1094.07 48.827,1106.42Z"
            style="fill: #f47433"
          />
        </g>
        <g
          id="step-3l-line-active"
          transform="matrix(0.432502,0,0,1.00894,22.6763,193.66)"
        >
          <path
            d="M48.827,374.513C48.827,373.33 46.587,372.37 43.829,372.37C41.07,372.37 38.831,373.33 38.831,374.513L38.831,444.206C38.831,445.388 41.07,446.348 43.829,446.348C46.587,446.348 48.827,445.388 48.827,444.206L48.827,374.513Z"
            :style="{
              fill: thirdStep ? '#f47433' : 'none',
            }"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { computed, inject, Ref } from "vue";
import { SidebarOption } from "@/interfaces";

export default {
  name: "ProgressBarVertical",
  props: ["isActive", "element"],
  setup() {
    const activeSidebarOption = inject("activeSidebarOption");

    const secondStep = computed(() => {
      return activeSidebarOption && activeSidebarOption.value.stepNumber === 2;
    });
    const thirdStep = computed(() => {
      return activeSidebarOption && activeSidebarOption.value.stepNumber === 3;
    });

    return {
      secondStep,
      thirdStep,
    };
  },
};
</script>
