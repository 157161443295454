<template>
  <image
    v-if="backFullLogo"
    :width="`${backFullLogo.size}%`"
    :height="`${backFullLogo.size}%`"
    :id="`backFull${elID}`"
    :href="backFullLogo.path"
    :style="{
      transform: `translate(-${
        backFullLogo.size / 2 - backFullLogo.offsetX
      }% , ${-backFullLogo.size / 2 + backFullLogo.offsetY}%)`,
      transformOrigin: `center center`,
    }"
  />
  <image
    v-if="backUpperLogo"
    :width="`${backUpperLogo.size}%`"
    :height="`${backUpperLogo.size}%`"
    :id="`backUpper${elID}`"
    :href="backUpperLogo.path"
    :style="{
      transform: `translate(-${
        backUpperLogo.size / 2 - backUpperLogo.offsetX
      }% , ${-backUpperLogo.size / 2 + backUpperLogo.offsetY}%)`,
      transformOrigin: `center center`,
    }"
  />
</template>

<script>
import { computed } from "vue";

export default {
  name: "BackLogoImages",
  props: ["logos", "id"],
  setup(props) {
    const elID = computed(() => props.id);
    const backFullLogo = computed(() => props.logos.backFullLogo);
    const backUpperLogo = computed(() => props.logos.backUpperLogo);

    return {
      elID,
      backFullLogo,
      backUpperLogo,
    };
  },
};
</script>

<style scoped></style>
