export default function useFrontDefaultCoordinatesDetector(
  productType: string,
  position: string
) {
  switch (productType) {
    case "polo":
    case "t_shirt":
    case "singlet":
    case "longsleeve":
    case "basketball_jerseys":
      if (position === "frontFull") {
        return { x: 1185, y: 1300 };
      } else {
        return { x: 1550, y: 800 }; // chest logo
      }
    case "cuthoodie":
    case "subhoodie":
      if (position === "frontFull") {
        return { x: 1185, y: 1550 };
      } else {
        return { x: 1600, y: 1100 }; // chest logo
      }
    default:
      return { x: 1185, y: 1300 };
  }
}
