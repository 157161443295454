export default function useProductSettings() {
  const prefill = function (product: any, settings: any) {
    if (settings.text) {
      product.text = settings.text;
    }
    if (settings.colors) {
      product.colors = settings.colors;
    }

    if (settings.logo) {
      const settingsObj = settings.logo;
      const imageObj = {
        offsetX: (settingsObj && settingsObj.offsetX) || 0,
        offsetY: (settingsObj && settingsObj.offsetY) || 0,
        path: (settingsObj && settingsObj.image) || "",
        position: (settingsObj && settingsObj.position) || 0,
        size: (settingsObj && settingsObj.size) || 0,
      };

      // Normalization of values
      const normalizationCallBack = function (key: string, val: any) {
        return key !== "path" ? parseInt(val) : val;
      };

      const newObject: any = {};
      Object.keys(imageObj).forEach((key) => {
        newObject[key] = normalizationCallBack(
          key,
          imageObj[key as keyof typeof imageObj]
        );
      });

      product.logo = newObject;
    } else {
      if (settings.logos) {
        for (const property in settings.logos) {
          const settingsObj = settings.logos[property];
          const imageObj = {
            offsetX: (settingsObj && settingsObj.offsetX) || 0,
            offsetY: (settingsObj && settingsObj.offsetY) || 0,
            path: (settingsObj && settingsObj.image) || "",
            position: (settingsObj && settingsObj.position) || 0,
            size: (settingsObj && settingsObj.size) || 0,
          };

          // Normalization of values
          const normalizationCallBack = function (key: string, val: any) {
            return key !== "path" ? parseInt(val) : val;
          };

          const newObject: any = {};
          Object.keys(imageObj).forEach((key) => {
            newObject[key] = normalizationCallBack(
              key,
              imageObj[key as keyof typeof imageObj]
            );
          });

          product.logo[`${property}`] = newObject;
        }
      }
    }
  };
  return { prefill };
}
