import { Ref } from "vue";
import { ICartItem, IProduct } from "@/interfaces";
import { v4 as uuid } from "uuid";
import useFlashNotifications from "@/hooks/flashNotifications";
import { useRouter } from "vue-router";
import { useCartStore } from "@/stores/CartStore";
import { useGtm } from "@gtm-support/vue-gtm";

export default function useCart(activeProduct: Ref<IProduct>) {
  const { showWarning } = useFlashNotifications();
  const router = useRouter();
  const cart = useCartStore();
  const gtm = useGtm();

  const getProductImages = () => {
    const svgFront = document.getElementById("svg-front");
    const svgBack = document.getElementById("svg-back");
    if (!svgFront || !svgBack) throw new Error("svg-not-defined");
    const front = new XMLSerializer().serializeToString(svgFront);
    const back = new XMLSerializer().serializeToString(svgBack);
    return { front, back };
  };

  const submitProduct = () => {
    if (activeProduct.value.quantity === 0 || !activeProduct.value.quantity) {
      return;
    }

    if (activeProduct.value.sizes && activeProduct.value.sizes !== null) {
      if (activeProduct.value.sizes.length === 0) {
        showWarning("Please select a minimum one size");
        return;
      }

      let itemQty = 0;
      let preventAddToCart = false;
      for (let i = 0; i < activeProduct.value.sizes.length; i++) {
        itemQty += activeProduct.value.sizes[i].quantity;
        if (!activeProduct.value.sizes[i].size) {
          preventAddToCart = true;
          break;
        }
      }

      if (preventAddToCart) {
        showWarning("Please select all sizes");
        return;
      }

      if (itemQty < activeProduct.value.minQty) {
        showWarning("Minimum quantity is " + activeProduct.value.minQty);
        return;
      }
    }

    if (activeProduct.value.playersDetails) {
      let preventAddToCart = false;
      for (let i = 0; i < activeProduct.value.playersDetails.length; i++) {
        if (!activeProduct.value.playersDetails[i].size) {
          preventAddToCart = true;
          break;
        }
      }

      // if a product has players settings but Size tab is not selected yet
      if (
        preventAddToCart === false &&
        activeProduct.value.playersDetails.length === 0
      ) {
        preventAddToCart = true;
      }

      if (preventAddToCart) {
        let alertQty = 0;
        if (activeProduct.value.playersDetails.length === 0) {
          alertQty = activeProduct.value.minQty;
        } else {
          alertQty = activeProduct.value.playersDetails.length;
        }

        showWarning("Please select a minimum of " + alertQty + " sizes");
        return;
      }
    }

    if (activeProduct.value.id) {
      const productImages = getProductImages();
      activeProduct.value.imageFront = productImages.front;
      activeProduct.value.imageBack = productImages.back;
      cart.replaceProduct(activeProduct.value as ICartItem);
      router.push({ name: "cart" });
      return;
    }
    activeProduct.value.id = uuid();
    const productImages = getProductImages();
    activeProduct.value.imageFront = productImages.front;
    activeProduct.value.imageBack = productImages.back;
    cart.addProduct(activeProduct.value as ICartItem);

    if (gtm && activeProduct.value.price && activeProduct.value.quantity) {
      const totalPrice =
        activeProduct.value.price * activeProduct.value.quantity;
      gtm.trackEvent({
        event: "add_to_cart",
        category: null,
        action: "click",
        currency: "AUD",
        value: totalPrice,
        items: [
          {
            item_id: activeProduct.value.databaseId,
            item_name: activeProduct.value.name,
            index: activeProduct,
            item_brand: "Cheeta",
            item_category: activeProduct.value.type,
            price: activeProduct.value.price,
            quantity: activeProduct.value.quantity,
          },
        ],

        noninteraction: false,
      });
    }

    router.push({ name: "related-items" });
  };

  return { submitProduct };
}
