import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8f8f5aa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel" }
const _hoisted_2 = { class: "tool-panel" }
const _hoisted_3 = { class: "buttons-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Sidebar, { onTabChanged: _ctx.changeTab }, null, 8, ["onTabChanged"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentTab.id), {
            onTextChanged: _ctx.textChanged,
            onLogoChanged: _ctx.logoChanged,
            onColorChanged: _ctx.colorChanged,
            onAddonsChanged: _ctx.addonsChanged,
            onTemplateChanged: _ctx.templateChanged,
            onSetOneOfTheLogos: _ctx.setOneOfTheLogos,
            onCheetaLogoChanged: _ctx.cheetaLogoChanged,
            onRemoveOneOfTheLogos: _ctx.removeOneOfTheLogos,
            onPlayerDetailsChanged: _ctx.changePlayersDetails,
            onQuantityChanged: _ctx.qtyChanged,
            onDetailsChanged: _ctx.onDetailsChange,
            onSizesDetailsChanged: _ctx.onSizeChange
          }, null, 40, ["onTextChanged", "onLogoChanged", "onColorChanged", "onAddonsChanged", "onTemplateChanged", "onSetOneOfTheLogos", "onCheetaLogoChanged", "onRemoveOneOfTheLogos", "onPlayerDetailsChanged", "onQuantityChanged", "onDetailsChanged", "onSizesDetailsChanged"]))
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Button, {
          disabled: _ctx.currentTab.id === 'templateTab',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.swithTab(-1))),
          label: "PREV",
          title: "Go to the previous tab"
        }, null, 8, ["disabled"]),
        (_ctx.currentTab.id !== 'logoTab' && _ctx.currentTab.id !== 'nameSizeTab')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.swithTab(1))),
              label: "NEXT",
              title: "Go to the next tab"
            }))
          : _createCommentVNode("", true),
        (_ctx.currentTab.id === 'logoTab' || _ctx.currentTab.id === 'nameSizeTab')
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              onClick: _ctx.submitProduct,
              label: "ADD TO CART",
              title: "Add to cart"
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}