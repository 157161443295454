import { createApp } from "vue";
import { createPinia } from "pinia";
import ToastService from "primevue/toastservice";

import PrimeVue from "primevue/config";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { createGtm } from "@gtm-support/vue-gtm";

// FONT AWESOME
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
const gtmDebug = process.env.VUE_APP_GTM_DEBUG || false;
const gtmID = process.env.VUE_APP_GTM_ID || false;

// library.add(faPlay);

const app = createApp(App);
const pinia = createPinia();
app.use(pinia);
app.use(PrimeVue);

app.use(router);

if (gtmID) {
  app.use(
    createGtm({
      id: gtmID,
      vueRouter: router,
      debug: gtmDebug,
    })
  );
}

// app.component("font-awesome-icon", FontAwesomeIcon);

app.use(ToastService);

app.use(VueAxios, axios); // 👈

app.mount("#app");
