<template>
  <image
    v-if="frontFullLogo"
    :y="startPositionFullLogoY"
    :x="startPositionFullLogoX"
    :width="`${frontFullLogo.size}%`"
    :height="`${frontFullLogo.size}%`"
    :id="`frontFull${id}`"
    :href="frontFullLogo.path"
    :style="{
      transform: `translate(-${
        frontFullLogo.size / 2 - frontFullLogo.offsetX
      }% , ${-frontFullLogo.size / 2 + frontFullLogo.offsetY}%)`,
      transformOrigin: `center center`,
    }"
  />
  <image
    v-if="frontChestLogo"
    :y="startPositionChestLogoY"
    :x="startPositionChestLogoX"
    :width="`${frontChestLogo.size}%`"
    :height="`${frontChestLogo.size}%`"
    :id="`frontChest${id}`"
    :href="frontChestLogo.path"
    :style="{
      transform: `translate(-${
        frontChestLogo.size / 2 - frontChestLogo.offsetX
      }% , ${-frontChestLogo.size / 2 + frontChestLogo.offsetY}%)`,
      transformOrigin: `center center`,
    }"
  />
</template>

<script>
import { computed, inject } from "vue";
import useFrontDefaultCoordinatesDetector from "@/hooks/frontDefaultCoordinatesDetector";

export default {
  name: "FrontLogoImages",
  props: ["logos"],
  setup(props) {
    const activeProductType = inject("activeProductType");
    const id = Math.floor(Math.random() * 10000).toString();
    const frontFullLogo = computed(() => {
      return props.logos.frontFullLogo;
    });
    const frontChestLogo = computed(() => props.logos.frontChestLogo);
    const { x: startPositionFullLogoX, y: startPositionFullLogoY } =
      useFrontDefaultCoordinatesDetector(activeProductType, "frontFull");

    const { x: startPositionChestLogoX, y: startPositionChestLogoY } =
      useFrontDefaultCoordinatesDetector(activeProductType, "chestLogo");
    return {
      id,
      frontFullLogo,
      frontChestLogo,
      startPositionFullLogoX,
      startPositionFullLogoY,
      startPositionChestLogoX,
      startPositionChestLogoY,
    };
  },
};
</script>

<style scoped></style>
