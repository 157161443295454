import { defineStore } from "pinia";
import { ref, computed } from "vue";
import type { Ref } from "vue";
import { IProduct, IUpsellItem } from "@/interfaces";
import { useStorage } from "@vueuse/core";

import { API } from "@/api";

export const useProductsStore = defineStore("ProductsStore", () => {
  const api = new API();
  const products: Ref<IProduct[]> = ref([
    {
      alias: "beanie_1",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#030404", name: "Black" },
        primary_2: { value: "#ffcd00", name: "Yellow" },
        secondary_1: { value: "#030404", name: "Black" },
        secondary_2: { value: "#ffcd00", name: "Yellow" },
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#ffcd00", name: "Yellow" },
        type: "front",
        font: "College",
        size: 490,
        back_side_text: null,
        max_chars: 12,
      },
      playersDetails: null,
      sizes: null,
      logo: { path: "", size: 35 },
      addons: [],
    },
    {
      alias: "beanie_2",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#3953a1", name: "royal blue" },
        primary_2: { value: "#00cd7e", name: "348c Green" },
        secondary_1: { value: "#00cd7e", name: "348c Green" },
        secondary_2: null,
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#ffffff", name: "White" },
        type: "front",
        font: "College",
        size: 329,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_3",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#db2828", name: "Red" },
        primary_2: { value: "#2d9859", name: "348c green" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: { value: "#2d9859", name: "348c green" },
      },
      details: null,
      text: {
        message: "",
        message2: "",
        color: { value: "#2d9859", name: "White" },
        type: "front",
        font: "College",
        size: 335,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_4",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#f78323", name: "165c Orange" },
        primary_2: { value: "#282929", name: "Charcoal" },
        secondary_1: { value: "#f78323", name: "165c Orange" },
        secondary_2: { value: "#282929", name: "White" },
      },
      details: null,
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_5",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ffffff", name: "165c Orange" },
        primary_2: { value: "#57c6ee", name: "Black" },
        secondary_1: { value: "#f78323", name: "165c Orange" },
        secondary_2: { value: "#57c6ee", name: "Black" },
      },
      details: null,
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_6",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ee2125", name: "Red" },
        primary_2: { value: "#030404", name: "Black" },
        secondary_1: { value: "#F5BD21", name: "Gold" },
        secondary_2: { value: "#030404", name: "Black" },
      },
      details: null,
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_7",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#0e1340", name: "289c Navy" },
        primary_2: { value: "#fdae1c", name: "Gold" },
        secondary_1: { value: "#0e1340", name: "289c Navy" },
        secondary_2: { value: "#fdae1c", name: "Gold" },
      },
      details: null,
      text: {
        message: "",
        message2: null,
        type: "wrapped",
        color: { value: "#0e1340", name: "289c Navy" },
        font: "College",
        size: 464,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [{ id: 1, name: "woven text on back", price: 0.5 }],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_8",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#f14036", name: "Red" },
        primary_2: { value: "#22336a", name: "It Navy" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: null,
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#23326a", name: "It NAvy" },
        type: "front",
        font: "College",
        size: 465,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_9",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#6cc8f1", name: "Sky BLue" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: { value: "#15264e", name: "289c Navy" },
        secondary_2: { value: "#6cc8f1", name: "Sky Blue" },
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#16254e", name: "289c Navy" },
        type: "front",
        font: "College",
        size: 400,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_10",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#981a2f", name: "Dark Red" },
        primary_2: { value: "#fac51c", name: "Gold" },
        secondary_1: { value: "#750f28", name: "Maroon" },
        secondary_2: { value: "#ffffff", name: "White" },
      },
      details: null,
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_11",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#31647e", name: "Teal Green" },
        primary_2: { value: "#141a2c", name: "289c Navy" },
        secondary_1: { value: "#f5b5a3", name: "Watermelon" },
        secondary_2: { value: "#b9906e", name: "Khaki" },
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#f4b5a3", name: "Watermelon" },
        type: "front",
        font: "College",
        size: 415,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "beanie_12",
      name: "",
      price: null,
      type: "beanie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#282828", name: "Dark Brown" },
        primary_2: { value: "#c78e3c", name: "Mustard" },
        secondary_1: { value: "#a53536", name: "Dark Red" },
        secondary_2: null,
      },
      details: null,
      text: {
        message: "",
        message2: null,
        color: { value: "#c68f3a", name: "Mustard" },
        type: "wrapped",
        font: "College",
        size: 266,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [{ id: 1, name: "woven text on back", price: 0.5 }],
      logo: { path: "", size: 35 },
    },

    // T-Shirt

    {
      alias: "t_shirt_1",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#442294", name: "Violet" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: { value: "#000000", name: "Black" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "t_shirt_2",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "rgb(0, 60, 0)", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_3",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_4",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#002352", name: "Blue" },
        primary_2: { value: "#4dc8f4", name: "Light Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_5",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#41484f", name: "Grey" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: { value: "#ff5000", name: "Orange" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_6",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#00103b", name: "Deep Blue" },
        primary_2: { value: "#ffac00", name: "Orange" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_7",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "t_shirt_8",
      name: "",
      price: null,
      type: "t_shirt",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#f9c747", name: "Yellow" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },

    // Longsleeve

    {
      alias: "longsleeve_1",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#442294", name: "Violet" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: { value: "#000000", name: "Black" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "longsleeve_2",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "rgb(0, 60, 0)", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_3",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_4",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#002352", name: "Blue" },
        primary_2: { value: "#4dc8f4", name: "Light Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_5",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#41484f", name: "Grey" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: { value: "#ff5000", name: "Orange" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_6",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#00103b", name: "Deep Blue" },
        primary_2: { value: "#ffac00", name: "Orange" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_7",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "longsleeve_8",
      name: "",
      price: null,
      type: "longsleeve",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#f9c747", name: "Yellow" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },

    //

    // SINGLET

    {
      alias: "singlet_1",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#442294", name: "Violet" },
        primary_2: { value: "#000000", name: "Balck" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "singlet_2",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "rgb(0, 60, 0)", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_3",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: { value: "#ff0000", name: "Red" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_4",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#4dc8f4", name: "Light Blue" },
        primary_2: { value: "#002352", name: "Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_5",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff5000", name: "Orange" },
        primary_2: { value: "#41484f", name: "Grey" },
        secondary_1: { value: "#000000", name: "Black" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_6",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#00103b", name: "Deep Blue" },
        primary_2: { value: "#ffac00", name: "Orange" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_7",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    {
      alias: "singlet_8",
      name: "",
      price: null,
      type: "singlet",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#f9c747", name: "Yellow" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: [],
      },
    },
    //

    // POLO
    {
      alias: "polo_1",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#cccccc", name: "Grey" },
        primary_2: { value: "#442294", name: "Violet" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_2",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "rgb(0, 60, 0)", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_3",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_4",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#002352", name: "Blue" },
        primary_2: { value: "#4dc8f4", name: "Light Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_5",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#41484f", name: "Grey" },
        primary_2: { value: "#ff5000", name: "Orange" },
        secondary_1: { value: "#000000", name: "Black" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_6",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#00103b", name: "Deep Blue" },
        primary_2: { value: "#ffac00", name: "Orange" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_7",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "polo_8",
      name: "",
      price: null,
      type: "polo",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#f9c747", name: "Yellow" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    //

    // SCARF

    {
      alias: "scarf_1",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "rgb(3, 4, 4)", name: "Default" },
        primary_2: { value: "rgb(151, 25, 48)", name: "Default" },
        secondary_1: { value: "rgb(253, 182, 20)", name: "Default" },
        secondary_2: { value: "rgb(253, 182, 20)", name: "Default" },
      },
      text: {
        message: "",
        message2: "",
        color: { value: "#fdb614", name: "Default" },
        type: "front",
        font: "CollegeBoldMT",
        size: 250,
        max_chars: 15,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "scarf_2",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "rgb(86, 51, 32)", name: "Default" },
        primary_2: { value: "rgb(247, 209, 77)", name: "Default" },
        secondary_1: { value: "rgb(86, 51, 32)", name: "Default" },
        secondary_2: { value: "rgb(247, 209, 77)", name: "Default" },
      },
      text: {
        message: "",
        message2: "",
        color: { value: "#563320", name: "Default" },
        type: "front",
        font: "CollegeBoldMT",
        size: 133.3,
        max_chars: 7,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "scarf_3",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "#6cc8f1", name: "Default" },
        primary_2: { value: "#15264e", name: "Default" },
        secondary_1: { value: "#ffffff", name: "Default" },
        secondary_2: { value: "#6cc8f1", name: "Default" },
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "scarf_4",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "#ef1e22", name: "Default" },
        primary_2: { value: "#ffffff", name: "Default" },
        secondary_1: { value: "#fffff", name: "Default" },
        secondary_2: { value: "#ef1e22", name: "Default" },
      },
      text: {
        message: "",
        message2: "",
        color: { value: "#f12020", name: "Red" },
        type: "front",
        font: "CollegeBoldMT",
        size: 250,
        max_chars: 7,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "scarf_5",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "#0e1340", name: "Dark Blue" },
        primary_2: { value: "#fdae1c", name: "Yellow" },
        secondary_1: { value: "#0e1340", name: "Dark Blue" },
        secondary_2: { value: "#fdae1c", name: "Yellow" },
      },
      text: {
        message: "",
        message2: "",
        color: { value: "#fcaf16", name: "Default" },
        type: "front",
        font: "CollegeBoldMT",
        size: 250,
        max_chars: 12,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "scarf_6",
      name: "",
      price: null,
      type: "scarf",
      databaseId: 0,
      minQty: 1,
      details: null,
      colors: {
        primary_1: { value: "#2c4727", name: "Default" },
        primary_2: { value: "#ffffff", name: "Default" },
        secondary_1: { value: "#2c4727", name: "Default" },
        secondary_2: { value: "#ffffff", name: "Default" },
      },
      text: {
        message: "",
        message2: null,
        color: { value: "#fff", name: "Default" },
        type: "front",
        font: "CollegeBoldMT",
        size: 220,
        max_chars: 8,
        back_side_text: null,
      },
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },

    // CUTHOODIE

    {
      alias: "cuthoodie_1",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#19073b", name: "Violet" },
        primary_2: { value: "#fff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_2",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#003c00", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_3",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_4",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#002352", name: "Blue" },
        primary_2: { value: "#4dc8f4", name: "Light Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_5",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#41484f", name: "Grey" },
        primary_2: { value: "#ff5000", name: "Orange" },
        secondary_1: { value: "#fff", name: "White" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_6",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#00103b", name: "Deep Blue" },
        primary_2: { value: "#ffac00", name: "Orange" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_7",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    {
      alias: "cuthoodie_8",
      name: "",
      price: null,
      type: "cuthoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#f9c747", name: "Yellow" },
        secondary_1: { value: "#ffac00", name: "Orange" },
        secondary_2: { value: "#ffcd00", name: "Default" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: [
        {
          id: 0,
          size: null,
          quantity: 1,
        },
      ],
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck"],
      },
    },
    //

    // SUBLIMATED HOODIES
    {
      alias: "subhoodie_1",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#19073b", name: "Violet" },
        primary_2: { value: "#fff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_2",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#003c00", name: "Green" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_3",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_4",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#002352", name: "Blue" },
        primary_2: { value: "#4dc8f4", name: "Light Blue" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_5",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#41484f", name: "Grey" },
        primary_2: { value: "#ff5000", name: "Orange" },
        secondary_1: { value: "#fff", name: "White" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_6",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ffac00", name: "Orange" },
        primary_2: { value: "#00103b", name: "Deep Blue" },
        secondary_1: { value: "#fff", name: "White" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_7",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ff0000", name: "Red" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    {
      alias: "subhoodie_8",
      name: "",
      price: null,
      type: "subhoodie",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#470024", name: "Dark Red" },
        primary_2: { value: "#fff", name: "White" },
        secondary_1: null,
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "leftChest",
        disabledPositions: ["neck", "rightBottom", "leftBottom"],
      },
    },
    //

    // BASKETBALL JERSEYS
    {
      alias: "basketball_jerseys_1",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#063ca7", name: "Blue" },
        primary_2: { value: "#fefefe", name: "White" },
        secondary_1: { value: "#e62129", name: "Red" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_2",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#030003", name: "Black" },
        primary_2: { value: "#b83335", name: "Dark Red" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: 30,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_3",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#a40e0f", name: "Red" },
        primary_2: { value: "#ffffff", name: "White" },
        secondary_1: { value: "#e62129", name: "Acid Red" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_4",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ec0498", name: "Pink" },
        primary_2: { value: "#0fcaed", name: "Turquoise" },
        secondary_1: { value: "#000000", name: "Black" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_5",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#e9b61f", name: "Yellow" },
        primary_2: { value: "#e31d1a", name: "Red" },
        secondary_1: { value: "#a51224", name: "Dark Red" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_6",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ffffff", name: "White" },
        primary_2: { value: "#d2202f", name: "Red" },
        secondary_1: { value: "#a51224", name: "Dark Red" },
        secondary_2: { value: "#0d223f", name: "Dark Blue" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_7",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ffffff", name: "White" },
        primary_2: { value: "#fdb927", name: "Yellow" },
        secondary_1: { value: "#045cc0", name: "Blue" },
        secondary_2: null,
      },
      text: null,
      details: {
        fontColor: { value: "#045cc0", name: "blue" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#045cc0", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    {
      alias: "basketball_jerseys_8",
      name: "",
      price: null,
      type: "basketball_jerseys",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#223e35", name: "Army Green" },
        primary_2: { value: "#ded0bd", name: "Olive" },
        secondary_1: { value: "#045cc0", name: "Blue" },
        secondary_2: { value: "#fefefe", name: "White" },
      },
      text: null,
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: "",
        teamNameSize: null,
      },
      sizes: null,
      playersDetails: [],
      addons: [],
      logo: {
        frontFull: null,
        frontCenter: null,
        frontChest: null,
        backFull: null,
        backCenter: null,
        backUpper: null,
      },
      cheetaLogo: {
        color: { value: "#fff", name: "White" },
        position: "rightChest",
        disabledPositions: [
          "neck",
          "leftChest",
          "rightBottom",
          "leftBottom",
          "back",
        ],
      },
    },
    //

    // BASKETBALL SHORTS
    {
      alias: "basketball_shorts_1",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#063ca7", name: "Blue" },
        primary_2: { value: "#fefefe", name: "Light Grey" },
        secondary_1: { value: "#e62129", name: "Red" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
      // ADD LOGO NULL TO PREVENT USER FROM SELECTING ANY LOGO
    },
    {
      alias: "basketball_shorts_2",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#130a0f", name: "Black" },
        primary_2: { value: "#b83335", name: "Red" },
        secondary_1: { value: "#a1a1a1", name: "Light Grey" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_3",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#a40e0f", name: "Red" },
        primary_2: { value: "#fff", name: "White" },
        secondary_1: { value: "#a1a1a1", name: "Light Grey" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_4",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ec0498", name: "Pink" },
        primary_2: { value: "#0fcaed", name: "Еurquoise" },
        secondary_1: { value: "#030003", name: "Black" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_5",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#cf112b", name: "Red" },
        primary_2: { value: "#282520", name: "Red Brown" },
        secondary_1: { value: "#972f32", name: "Dark Red" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_6",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#fff", name: "White" },
        primary_2: { value: "#0d223f", name: "Dark Blue" },
        secondary_1: { value: "#d2202f", name: "Red" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_7",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#fff", name: "White" },
        primary_2: { value: "#045cc0", name: "Blue" },
        secondary_1: { value: "#fdb927", name: "Yellow" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "basketball_shorts_8",
      name: "",
      price: null,
      type: "basketball_shorts",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#223e35", name: "Grey" },
        primary_2: { value: "#fefefe", name: "Light Grey" },
        secondary_1: null,
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    //

    // TRUCKER HATS

    {
      alias: "truckercap_1",
      name: "",
      price: null,
      type: "truckercap",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: { value: "#26272c", name: "Black" },
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },

    //

    // SUBLIMATED BUCKET HATS

    {
      alias: "sub_bucket_hat_1",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_2",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_3",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: { value: "#26272c", name: "Black" },
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_4",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_5",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_6",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#26272c", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_7",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#ffffff", name: "White" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "sub_bucket_hat_8",
      name: "",
      price: null,
      type: "sub_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#90c95e", name: "Bright Green" },
        primary_2: { value: "#000000", name: "Black" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },

    // TERRY BUCKET HATS
    {
      alias: "terry_bucket_hat_1",
      name: "",
      price: null,
      type: "terry_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: null,
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    // Cut & Sew Bucket Hats

    {
      alias: "cut_bucket_hat_1",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 20 },
    },

    {
      alias: "cut_bucket_hat_2",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    {
      alias: "cut_bucket_hat_3",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    {
      alias: "cut_bucket_hat_4",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    {
      alias: "cut_bucket_hat_5",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: null,
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    {
      alias: "cut_bucket_hat_6",
      name: "",
      price: null,
      type: "cut_bucket_hat",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#26272c", name: "Black" },
        primary_2: { value: "#fd0008", name: "Red" },
        secondary_1: { value: "#ffffff", name: "White" },
        secondary_2: null,
      },
      details: {
        fontColor: { value: "#fff", name: "White" },
        fontFamily: "College",
        teamName: null,
        teamNameSize: null,
      },
      text: null,
      sizes: null,
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 25 },
    },

    // SOCKS
    {
      alias: "crewsock_1",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#fff", name: "White" },
        primary_2: { value: "#ea1d25", name: "Red" },
        secondary_1: { value: "#151f50", name: "Dark Blue" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "crewsock_2",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#fff", name: "White" },
        primary_2: { value: "#0f6735", name: "Green" },
        secondary_1: { value: "#fff10f", name: "Yellow" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "crewsock_3",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#132042", name: "Dark Blue" },
        primary_2: { value: "#fcb525", name: "Yellow" },
        secondary_1: null,
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "crewsock_4",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#000000", name: "Black" },
        primary_2: { value: "#cf2026", name: "Red" },
        secondary_1: { value: "#fff", name: "White" },
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "crewsock_5",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#fff", name: "White" },
        primary_2: { value: "#0f3a1f", name: "Dark Green" },
        secondary_1: null,
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    {
      alias: "crewsock_6",
      name: "",
      price: null,
      type: "crewsock",
      databaseId: 0,
      minQty: 1,
      colors: {
        primary_1: { value: "#009588", name: "Turquoise" },
        primary_2: { value: "#7f327d", name: "Lavender" },
        secondary_1: null,
        secondary_2: null,
      },
      details: null,
      text: null,
      sizes: [],
      playersDetails: null,
      addons: [],
      logo: { path: "", size: 35 },
    },
    //
  ]);
  const productsTemplates = ref([
    {
      id: 1,
      pathToImage: require("@/assets/svg/beanie-1-front.svg"),
      product: "beanie",
      alias: "beanie_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/beanie-2-front.svg"),
      product: "beanie",
      alias: "beanie_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/beanie-3-front.svg"),
      product: "beanie",
      alias: "beanie_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/beanie-4-front.svg"),
      product: "beanie",
      alias: "beanie_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/beanie-5-front.svg"),
      product: "beanie",
      alias: "beanie_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/beanie-6-front.svg"),
      product: "beanie",
      alias: "beanie_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/beanie-7-front.svg"),
      product: "beanie",
      alias: "beanie_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/beanie-8-front.svg"),
      product: "beanie",
      alias: "beanie_8",
    },
    {
      id: 9,
      pathToImage: require("@/assets/svg/beanie-9-front.svg"),
      product: "beanie",
      alias: "beanie_9",
    },
    {
      id: 10,
      pathToImage: require("@/assets/svg/beanie-10-front.svg"),
      product: "beanie",
      alias: "beanie_10",
    },
    {
      id: 11,
      pathToImage: require("@/assets/svg/beanie-11-front.svg"),
      product: "beanie",
      alias: "beanie_11",
    },
    {
      id: 12,
      pathToImage: require("@/assets/svg/beanie-12-front.svg"),
      product: "beanie",
      alias: "beanie_12",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/tshirt-1-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/tshirt-2-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/tshirt-3-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/tshirt-4-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/tshirt-5-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/tshirt-6-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/tshirt-7-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/tshirt-8-front.svg"),
      product: "t_shirt",
      alias: "t_shirt_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/longsleeve-1-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/longsleeve-2-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/longsleeve-3-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/longsleeve-4-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/longsleeve-5-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/longsleeve-6-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/longsleeve-7-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/longsleeve-8-front.svg"),
      product: "longsleeve",
      alias: "longsleeve_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/scarf-1-front.svg"),
      product: "scarf",
      alias: "scarf_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/scarf-2-front.svg"),
      product: "scarf",
      alias: "scarf_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/scarf-3-front.svg"),
      product: "scarf",
      alias: "scarf_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/scarf-4-front.svg"),
      product: "scarf",
      alias: "scarf_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/scarf-5-front.svg"),
      product: "scarf",
      alias: "scarf_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/scarf-6-front.svg"),
      product: "scarf",
      alias: "scarf_6",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/cuthoodie-1-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/cuthoodie-2-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/cuthoodie-3-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/cuthoodie-4-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/cuthoodie-5-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/cuthoodie-6-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/cuthoodie-7-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/cuthoodie-8-front.svg"),
      product: "cuthoodie",
      alias: "cuthoodie_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/subhoodie-1-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/subhoodie-2-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/subhoodie-3-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/subhoodie-4-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/subhoodie-5-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/subhoodie-6-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/subhoodie-7-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/subhoodie-8-front.svg"),
      product: "subhoodie",
      alias: "subhoodie_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/truckercap-1-side.svg"),
      product: "truckercap",
      alias: "truckercap_1",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/cutbucket-1-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/cutbucket-2-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/cutbucket-3-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/cutbucket-4-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/cutbucket-5-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/cutbucket-6-front.svg"),
      product: "cut_bucket_hat",
      alias: "cut_bucket_hat_6",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/terrybucket-1-front.svg"),
      product: "terry_bucket_hat",
      alias: "terry_bucket_hat_1",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/subbucket-1-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/subbucket-2-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/subbucket-3-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/subbucket-4-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/subbucket-5-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/subbucket-6-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/subbucket-7-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/subbucket-8-front.svg"),
      product: "sub_bucket_hat",
      alias: "sub_bucket_hat_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/singlet-1-front.svg"),
      product: "singlet",
      alias: "singlet_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/singlet-2-front.svg"),
      product: "singlet",
      alias: "singlet_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/singlet-3-front.svg"),
      product: "singlet",
      alias: "singlet_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/singlet-4-front.svg"),
      product: "singlet",
      alias: "singlet_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/singlet-5-front.svg"),
      product: "singlet",
      alias: "singlet_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/singlet-6-front.svg"),
      product: "singlet",
      alias: "singlet_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/singlet-7-front.svg"),
      product: "singlet",
      alias: "singlet_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/singlet-8-front.svg"),
      product: "singlet",
      alias: "singlet_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/polo-1-front.svg"),
      product: "polo",
      alias: "polo_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/polo-2-front.svg"),
      product: "polo",
      alias: "polo_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/polo-3-front.svg"),
      product: "polo",
      alias: "polo_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/polo-4-front.svg"),
      product: "polo",
      alias: "polo_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/polo-5-front.svg"),
      product: "polo",
      alias: "polo_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/polo-6-front.svg"),
      product: "polo",
      alias: "polo_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/polo-7-front.svg"),
      product: "polo",
      alias: "polo_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/polo-8-front.svg"),
      product: "polo",
      alias: "polo_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/basketball-jersey-1-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/basketball-jersey-2-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/basketball-jersey-3-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/basketball-jersey-4-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/basketball-jersey-5-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/basketball-jersey-6-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/basketball-jersey-7-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/basketball-jersey-8-front.svg"),
      product: "basketball_jerseys",
      alias: "basketball_jerseys_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/basketball-shorts-1-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/basketball-shorts-2-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/basketball-shorts-3-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/basketball-shorts-4-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/basketball-shorts-5-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/basketball-shorts-6-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_6",
    },
    {
      id: 7,
      pathToImage: require("@/assets/svg/basketball-shorts-7-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_7",
    },
    {
      id: 8,
      pathToImage: require("@/assets/svg/basketball-shorts-8-front.svg"),
      product: "basketball_shorts",
      alias: "basketball_shorts_8",
    },
    {
      id: 1,
      pathToImage: require("@/assets/svg/crew-socks-1-front.svg"),
      product: "crewsock",
      alias: "crewsock_1",
    },
    {
      id: 2,
      pathToImage: require("@/assets/svg/crew-socks-2-front.svg"),
      product: "crewsock",
      alias: "crewsock_2",
    },
    {
      id: 3,
      pathToImage: require("@/assets/svg/crew-socks-3-front.svg"),
      product: "crewsock",
      alias: "crewsock_3",
    },
    {
      id: 4,
      pathToImage: require("@/assets/svg/crew-socks-4-front.svg"),
      product: "crewsock",
      alias: "crewsock_4",
    },
    {
      id: 5,
      pathToImage: require("@/assets/svg/crew-socks-5-front.svg"),
      product: "crewsock",
      alias: "crewsock_5",
    },
    {
      id: 6,
      pathToImage: require("@/assets/svg/crew-socks-6-front.svg"),
      product: "crewsock",
      alias: "crewsock_6",
    },
  ]);
  const productSettings = ref({}) as Ref<{ productionTime: string }>;
  const colorOptions = useStorage(
    "ColorOptions",
    ref([{ value: "#FFF450", name: "BRIGHT YELLOW" }])
  );
  const sizeOptions: Ref<{ id: number; name: string }[]> = useStorage(
    "SizeOptions",
    ref([])
  );
  const productCategoryName: Ref<string> = useStorage(
    "ProductCategoryName",
    ref("")
  );
  const productCategoryPageTitle: Ref<string> = useStorage(
    "ProductCategoryPageTitle",
    ref("")
  );
  const productCategoryPdfs: Ref<Array<string | null>> = useStorage(
    "ProductCategoryPdfs",
    ref([])
  );
  const productCategories: Ref<
    {
      alias: string;
      name: string;
      title: string;
      description: string;
      h1: string;
      productInfo: null | Array<string>;
    }[]
  > = useStorage("ProductCategories", ref([]));
  const productsMinimumQuantities = ref([]);
  const productsMeta: Ref<any[]> = ref([]);
  // Getters
  const getProductTemplates = computed(() => {
    return (productType: string) => {
      const templates = productsTemplates.value.filter(
        (el) => el.product === productType
      );
      if (!templates.length)
        throw new Error("Template for this product are empty");
      return templates;
    };
  });
  const getProductMeta = computed(() => {
    return productsMeta.value || [];
  });
  const getColorOptions = computed(() => {
    return colorOptions;
  });
  // TODO: TEMPORARY SOLUTION WILL BE REMOVED SOON ( I HOPE )
  const getColorOptionsForHats = computed(() => {
    return {
      primary_1: [
        { name: "black", value: "#000000" },
        { name: "white", value: "#ffffff" },
        { name: "off white", value: "#f6f7f2" },
        { name: "red", value: "#db4033" },
        { name: "magenta", value: "#d65c82" },
        { name: "pink", value: "#e6a1b0" },
        { name: "light pink", value: "#e3a1a1" },
        { name: "navy", value: "#1f2e4d" },
        { name: "royal blue", value: "#385c96" },
        // { name: "royal blue", value: "#002e99" },
        { name: "yellow", value: "#e6b859" },
        { name: "gold", value: "#d19e61" },
        // { name: "gold", value: "#ff8c00" },
        { name: "orange", value: "#d46945" },
        { name: "neon green", value: "#2e6645" },
        { name: "bottle green", value: "#243833" },
        { name: "olive green", value: "#4a4a38" },
        { name: "army green", value: "#615c47" },
        { name: "grey", value: "#808787" },
        { name: "khaki", value: "#b09e85" },
        { name: "coffee", value: "#211c19" },
        { name: "brown", value: "#664533" },
        { name: "purple", value: "#615780" },
        { name: "teal blue", value: "#a1b3c7" },
        { name: "sky blue", value: "#6b9ec7" },
        { name: "champagne", value: "#8c7d61" },
      ],
      primary_2: [
        { name: "black", value: "#000000" },
        { name: "white", value: "#ffffff" },
        { name: "off white", value: "#f6f7f2" },
        { name: "red", value: "#db4033" },
        { name: "dark red", value: "#b41d2e" },
        { name: "peach red", value: "#f25172" },
        { name: "magenta", value: "#d65c82" },
        { name: "dark pink", value: "#d98795" },
        { name: "light pink", value: "#e3a1a1" },
        { name: "dark navy", value: "#352e3e" },
        { name: "light navy", value: "#383c50" },
        { name: "navy blue", value: "#1c213e" },
        { name: "dark royal blue", value: "#17356d" },
        { name: "yellow", value: "#e6b859" },
        // { name: "gold", value: "#ff8c00" },
        { name: "gold", value: "#d19e61" },
        { name: "goose yellow", value: "#dcc963" },
        { name: "orange", value: "#d46945" },
        { name: "neon green", value: "#19cc00" },
        { name: "bottle green", value: "#243833" },
        { name: "green", value: "#00804c" },
        { name: "olive green", value: "#4a4a38" },
        { name: "army green", value: "#615c47" },
        { name: "light grey", value: "#808787" },
        { name: "dark grey", value: "#4d5a62" },
        { name: "khaki", value: "#b09e85" },
        { name: "coffee", value: "#211c19" },
        { name: "brown", value: "#664533" },
        { name: "purple", value: "#615780" },
        { name: "teal blue", value: "#a1b3c7" },
        { name: "sky blue", value: "#6b9ec7" },
      ],
      secondary_1: [
        { name: "pink", value: "#e6a1b0" },
        { name: "orange", value: "#ff4c00" },
        { name: "neon green", value: "#18cc00" },
        { name: "vibrant red", value: "#ff003b" },
        { name: "white", value: "#ffffff" },
        { name: "black", value: "#000000" },
        { name: "raspberry rose", value: "#de0047" },
        { name: "teal green", value: "#009e8f" },
        { name: "tangerine", value: "#ff1f1c" },
        { name: "green", value: "#00804c" },
        { name: "navy", value: "#1f2e4d" },
        { name: "olive green", value: "#4a4a38" },
        { name: "red", value: "#db4033" },
        { name: "grey", value: "#808787" },
        { name: "sky blue", value: "#219bf2" },
        { name: "gold", value: "#ff8c00" },
        { name: "yellow", value: "#f0c800" },
        { name: "royal blue", value: "#002e99" },
        { name: "maroon", value: "#4a1c36" },
      ],
    };
  });
  const getSizeOptions = computed(() => {
    return sizeOptions.value;
  });
  const getProductCategoryName = computed(() => {
    return productCategoryName.value;
  });
  const getProductCategoryPdfs = computed(() => {
    return productCategoryPdfs.value;
  });
  const getProductCategories = computed(() => {
    return productCategories.value;
  });
  const getProductByDbId = (el: IUpsellItem) => {
    return products.value.find(
      (product: IProduct) => product.alias === el.alias
    );
  };
  const getMinimumQuantities = computed(() => {
    return productsMinimumQuantities.value;
  });
  const getProductSettings = computed(() => {
    return productSettings.value;
  });
  // Mutations
  function setProductSettings(settings: { productionTime: string }) {
    productSettings.value = settings;
  }
  function setColorOptions(colors: { value: string; name: string }[]) {
    colorOptions.value = colors;
  }
  function setSizeOptions(data: Array<{ id: number; name: string }>) {
    sizeOptions.value = data;
  }
  function setProductCategoryName(name: string) {
    productCategoryName.value = name;
  }
  function setProductCategoryPdfs(data: Array<string | null>) {
    productCategoryPdfs.value = data;
  }
  function setProductCategories(
    data: Array<{
      alias: string;
      name: string;
      title: string;
      description: string;
      h1: string;
      productInfo: null | Array<string>;
    }>
  ) {
    productCategories.value = data;
  }
  function getItemByAlias(alias: string) {
    const product = products.value.find(
      (product: IProduct) => product.alias === alias
    );
    if (!product) throw new Error(`Product not found`);
    return product;
  }
  const fetchProductsMeta = async (companyId: number) => {
    const response = await api.getProductsMeta(companyId);
    productsMeta.value = response.data;
    const categories = [];
    for (const key in response.data) {
      categories.push({
        alias: response.data[key].alias,
        name: response.data[key].name,
        title: response.data[key].settings.title,
        description: response.data[key].settings.description,
        h1: response.data[key].settings.h1,
        productInfo: response.data[key].descriptions,
      });
    }
    productCategories.value = categories;
  };

  const removeInactiveProducts = (
    productsFromDB: IProduct[],
    productsAliasesFromDB: string[]
  ) => {
    const filteredProducts = productsFromDB.filter((el) =>
      productsAliasesFromDB.includes(el.alias)
    );
    products.value = filteredProducts;
  };
  const removeInactiveTemplates = (activeAliasesArray: any) => {
    productsTemplates.value = productsTemplates.value.filter((el) =>
      activeAliasesArray.includes(el.alias)
    );
  };

  const updateProductsInfo = async (companyId: number) => {
    const response = await api.getProductsInfo(companyId);
    const activeAliasesArray = response.data.data.map((el: any) => {
      return el.alias;
    });
    removeInactiveProducts(products.value, activeAliasesArray);
    removeInactiveTemplates(activeAliasesArray);
    response.data.data.forEach(
      (item: {
        minQty: number | null;
        name: string;
        id: number;
        alias: string;
      }) => {
        const product = products.value.find((el) => el.alias === item.alias);
        if (!product) return;
        product.name = item.name;
        product.databaseId = item.id;
        product.minQty = item.minQty || 1;
      }
    );
  };

  return {
    products,

    colorOptions,
    productsTemplates,
    productsMinimumQuantities,

    getColorOptionsForHats,

    getProductCategories,
    setProductCategories,

    getProductMeta,
    setSizeOptions,
    setProductCategoryName,
    setProductCategoryPdfs,
    getItemByAlias,
    getSizeOptions,
    getProductCategoryName,
    getProductCategoryPdfs,
    getColorOptions,
    setColorOptions,
    getProductByDbId,
    getProductTemplates,
    getMinimumQuantities,
    fetchProductsMeta,
    updateProductsInfo,

    setProductSettings,
    getProductSettings,
  };
});
