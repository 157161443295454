
import { computed, defineComponent } from "vue";
import Button from "primevue/button";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CompanyNotFoundView",
  components: {
    Button,
  },
  setup() {
    const router = useRouter();
    const goHome = () => {
      const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
      window.location.href = baseUrl.value;
      // router.push({ name: "home" });
    };
    return {
      goHome,
    };
  },
});
