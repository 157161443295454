import { useProductsStore } from "@/stores/ProductsStore";
import { Ref, ref } from "vue";

export default function useProductInfo(productType: string) {
  const descriptions: Ref<null | Array<string>> = ref(null);
  const store = useProductsStore();
  const categories = store.getProductCategories;

  const searchResult = categories.find(function (el) {
    return el.alias === productType;
  });

  if (searchResult) {
    descriptions.value = searchResult.productInfo;
  }

  return descriptions;
}
