
import { defineComponent, ref, inject, computed } from "vue";
import type { Ref } from "vue";
import DisclaimerText from "@/components/Common/DisclaimerText.vue";

import Button from "primevue/button";
import Slider from "primevue/slider";
import Checkbox from "primevue/checkbox";
import { IAddon, IProduct, SidebarOption } from "@/interfaces";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "LogoTab",
  emits: ["logoChanged", "addonsChanged"],
  components: {
    Slider,
    Button,
    Checkbox,
    DisclaimerText,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const loadedImage: Ref<string> = ref("");
    const activeProduct: any | undefined = inject("activeProduct");
    if (!activeProduct) throw new Error("selected product is undefined");

    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );

    let logoSizeFromSettings = activeProduct.value.logo.size ?? 25;
    const logoSize: Ref<number> = ref(logoSizeFromSettings);
    const availableAddons: Ref<IAddon[]> | undefined =
      inject("availableAddons");
    if (!availableAddons)
      throw new Error("Available addons are undefined (LOGO TAB)");
    const isBadgeAddonAvailable = computed(() => {
      const badgeAddon = availableAddons.value.find(
        (el) => el.name == "sublimated badge"
      );
      if (!badgeAddon) return false;
      return true;
    });

    const productType: Ref<string> | undefined = inject("activeProductType");
    const selectedAddons: Ref<IAddon[]> | undefined = inject("selectedAddons");
    if (!selectedAddons) throw new Error("selected addons is undefined");
    const sublimatedBadgeAddonState: Ref<boolean> = ref(false);
    const addonIndexInSelectedAddons = selectedAddons.value.findIndex(
      (el) => el.id === 3 // (Sublimated badge addon ID is '3')
    );
    if (addonIndexInSelectedAddons >= 0) sublimatedBadgeAddonState.value = true;

    const providedLogo: Ref<{ path: string; size: number }> | undefined =
      inject("logoImage");
    if (providedLogo && providedLogo.value.path)
      loadedImage.value = providedLogo.value.path;

    const onLogoUpload = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (!files || !files[0]) return;

      let imageFile = files[0]; // Image
      var reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = function (e) {
        if (e && e.target) {
          const image = e.target.result;
          loadedImage.value = image as string;
          emit("logoChanged", { path: image, size: logoSize.value });
        }
      };
    };
    const onBadgeTypeChange = () => {
      emit("addonsChanged", {
        id: 3,
        name: "sublimated badge",
        price: 0.5,
      });
    };
    const logoSizeChange = (e: any) => {
      emit("logoChanged", { path: loadedImage.value, size: logoSize.value });
    };
    const removeLogo = () => {
      const input = document.getElementById(
        "upload-button"
      ) as HTMLInputElement;
      input.value = "";
      loadedImage.value = "";
      emit("logoChanged", { path: "", size: 35 });
    };
    return {
      accentColor,
      accentColorHover,
      activeSidebarOption,
      baseUrl,
      availableAddons,
      onLogoUpload,
      isBadgeAddonAvailable,
      removeLogo,
      loadedImage,
      logoSizeChange,
      logoSize,
      onBadgeTypeChange,
      productType,
      sublimatedBadgeAddonState,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "81d0feb2": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__