import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30cabc86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spinner"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "panels" }
const _hoisted_4 = { class: "constructor-panel" }
const _hoisted_5 = { class: "preview-panel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Header = _resolveComponent("Header")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_ConstructorPanel = _resolveComponent("ConstructorPanel")!
  const _component_PreviewPanel = _resolveComponent("PreviewPanel")!
  const _component_PreviewPanelMobile = _resolveComponent("PreviewPanelMobile")!
  const _component_Toast = _resolveComponent("Toast")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ProgressSpinner)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_ctx.windowWidthDetection === 'Desktop')
          ? (_openBlock(), _createBlock(_component_Header, {
              key: 0,
              category: _ctx.categoryName
            }, null, 8, ["category"]))
          : (_openBlock(), _createBlock(_component_MobileHeader, {
              key: 1,
              category: _ctx.categoryName
            }, null, 8, ["category"])),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ConstructorPanel, {
              onChangeTab: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebar.changeTab($event))),
              onAddonsChanged: _ctx.changeAddons,
              onQuantityChanged: _ctx.onQtyChange,
              onTextChanged: _ctx.changeProductText,
              onLogoChanged: _ctx.changeProductLogo,
              onColorChanged: _ctx.changeProductColor,
              onSetOneOfTheLogos: _ctx.addOneOfTheLogos,
              onCheetaLogoChanged: _ctx.changeCheetaLogo,
              onTemplateChanged: _ctx.changeProductTemplate,
              onSwitchToNextTab: _ctx.sidebar.switchToNextTab,
              onRemoveOneOfTheLogos: _ctx.removeOneOfTheLogos,
              onPlayerDetailsChanged: _ctx.changePlayerDetails,
              onDetailsChanged: _ctx.changeDetails,
              onSizeChanged: _ctx.changeSizes
            }, null, 8, ["onAddonsChanged", "onQuantityChanged", "onTextChanged", "onLogoChanged", "onColorChanged", "onSetOneOfTheLogos", "onCheetaLogoChanged", "onTemplateChanged", "onSwitchToNextTab", "onRemoveOneOfTheLogos", "onPlayerDetailsChanged", "onDetailsChanged", "onSizeChanged"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.activeProduct && _ctx.windowWidthDetection === 'Desktop')
              ? (_openBlock(), _createBlock(_component_PreviewPanel, {
                  key: 0,
                  onQuantityChanged: _ctx.onQtyChange,
                  onDesignSaved: _ctx.saveDesign
                }, null, 8, ["onQuantityChanged", "onDesignSaved"]))
              : (_ctx.activeProduct)
                ? (_openBlock(), _createBlock(_component_PreviewPanelMobile, {
                    key: 1,
                    onQuantityChanged: _ctx.onQtyChange,
                    onDesignSaved: _ctx.saveDesign
                  }, null, 8, ["onQuantityChanged", "onDesignSaved"]))
                : _createCommentVNode("", true)
          ]),
          _createVNode(_component_Toast)
        ])
      ]))
}