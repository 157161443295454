
import { defineComponent, computed, ref, inject } from "vue";
import type { Ref } from "vue";

import * as productSvgs from "@/components/SVGImagesAsComponents/index";

import Button from "primevue/button";
import { IAddon, ICartItem } from "@/interfaces";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Skeleton from "primevue/skeleton";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Decimal from "decimal.js";

import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFlip, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "CartPanel",
  emits: ["deleteItem", "editDesign", "quantityChanged"],
  components: {
    ...productSvgs,

    Button,
    Dropdown,
    InputNumber,
    Skeleton,
    Accordion,
    AccordionTab,

    Swiper,
    SwiperSlide,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    // TODO: SPLIT PRICE FETCHING LOGIC TO SEPARATE FILES
    const orderedItems: Array<ICartItem> | undefined = inject("orderedItems");
    const priceLoading: Ref<{ loading: boolean; id: string }> | undefined =
      inject("priceLoading");
    if (!priceLoading) throw new Error("price loading state is undefined");
    const editDesign = (product: ICartItem) => {
      emit("editDesign", product);
    };
    const deleteItem = (item: ICartItem) => {
      emit("deleteItem", item);
    };
    const changeQTY = (qty: number, id: string, databaseId: number) => {
      emit("quantityChanged", qty, id, databaseId);
    };
    const getPrice = (
      elementPrice: number,
      elementQuantity: number,
      elementAddons: Array<IAddon>
    ) => {
      const addonsPrice = elementAddons
        ? elementAddons.reduce((sum, currentItem) => {
            return sum + currentItem.price;
          }, 0)
        : 0;
      const price = (elementPrice + addonsPrice) * elementQuantity;
      return new Decimal(price.toFixed(2))
        .toNumber()
        .toLocaleString("en-US", { minimumFractionDigits: 2 });
    };
    return {
      modules: [EffectFlip, Pagination, Navigation],

      getPrice,
      editDesign,
      deleteItem,
      priceLoading,
      orderedItems,
      changeQTY,
      accentColor,
      accentColorHover,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "02e3f08c": (_ctx.accentColorHover),
  "f01bfae0": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__