import { ref } from "vue";
import { API } from "@/api";
import { useCartStore } from "@/stores/CartStore";
import Decimal from "decimal.js";
export const priceLoading = ref(true);
export const fetchActualPrice = async (
  id: number,
  addons: string,
  quantity: number
) => {
  const api = new API();
  const cart = useCartStore();
  priceLoading.value = true;
  let price = await api.fetchItemPrice(1, id, addons, quantity);
  if (quantity > 0) price = price / quantity;
  cart.setPrice(price);
  priceLoading.value = false;
};
