import { computed } from "vue";
import { useCompanyStore } from "@/stores/CompanyStore";

export default function useThemeSettings() {
  const companyStore = useCompanyStore();
  const companyInfo: any = companyStore.getCompanyInfo;

  const getAccentColor = computed(() => {
    return companyInfo.accentColor ? companyInfo.accentColor : "#ff7434";
  });

  const getAccentColorHover = computed(() => {
    return companyInfo.accentColorHover
      ? companyInfo.accentColorHover
      : "#db6128";
  });

  return {
    getAccentColor,
    getAccentColorHover,
  };
}
