import { useProductsStore } from "@/stores/ProductsStore";
import { useCartStore } from "@/stores/CartStore";
import { useCompanyStore } from "@/stores/CompanyStore";

export const getMetaForCurrentProduct = async (type: string) => {
  const store = useProductsStore();
  const cart = useCartStore();
  // const aliases = {
  //   beanie: "Beanies",
  //   singlet: "Singlets",
  //   t_shirt: "Sublimated T-Shirts",
  //   longsleeve: "Long Sleeve Tees",
  //   cuthoodie: "Cut & Sew Hoodies",
  //   truckercap: "Trucker Caps",
  //   scarf: "Scarves",
  //   crewsock: "Socks",
  //   polo: "Polo Shirts",
  //   subhoodie: "Sublimated Hoodies",
  //   basketball_shorts: "Basketball Shorts",
  //   basketball_jerseys: "Basketball Jerseys",
  //   sub_bucket_hat: "Sublimated Bucket Hats",
  //   cut_bucket_hat: "Cut & Sew Bucket Hats",
  //   terry_bucket_hat: "Terry Towelling Bucket Hat",
  // };
  if (!store.getProductMeta.length) {
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }
    await store.fetchProductsMeta(companyInfo.id);
  }
  const allProductsMeta = store.getProductMeta;

  const currentProductMeta = allProductsMeta.find(
    (el: any) => el.alias === type
  );
  if (!currentProductMeta) throw new Error("Current product meta is undefined");
  store.setSizeOptions(currentProductMeta.sizes);
  store.setProductCategoryName(currentProductMeta.name);
  store.setProductCategoryPdfs(currentProductMeta.pdfs);
  cart.setMinQty(currentProductMeta.minQuantity);
  store.setColorOptions(currentProductMeta.colors);
  cart.setAvailableAddons(currentProductMeta.addons);
  store.setProductSettings(currentProductMeta.settings);
};
