
import { defineComponent, onMounted, ref } from "vue";
import router from "@/router";
import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
import { API } from "@/api";
import type { Ref } from "vue";
import { IProductDescription } from "@/interfaces";
import Skeleton from "primevue/skeleton";
import { useProductsStore } from "@/stores/ProductsStore";
import { useCompanyStore } from "@/stores/CompanyStore";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "HomeView",
  components: {
    Header,
    Footer,
    Skeleton,
  },
  setup() {
    const h1Text: Ref<string> = ref("All Products");
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const products: Ref<Array<IProductDescription>> = ref([]);
    const api = new API();
    const loading = ref(true);

    onMounted(async () => {
      const companyStore = useCompanyStore();
      const companyInfo: any = companyStore.getCompanyInfo;
      if (!companyInfo) {
        throw new Error("Company not found");
      }
      if (companyInfo.mainPageH1) {
        h1Text.value = companyInfo.mainPageH1;
      }

      const productsData = await api.getProductsMeta(companyInfo.id);
      products.value = productsData.data ? productsData.data : [];
      loading.value = false;
    });
    const goToDesign = (product: IProductDescription) => {
      const store = useProductsStore();
      if (product.colors) {
        store.setColorOptions(product.colors);
      }
      if (product.sizes.length) {
        store.setSizeOptions(product.sizes);
      }
      router.push({
        name: "constructor",
        params: { product: product.alias },
      });
    };
    return {
      loading,
      products,
      goToDesign,
      accentColor,
      h1Text,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4d194ba2": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__