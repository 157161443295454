
import { defineComponent, ref, Ref, inject, computed } from "vue";
import useChartsSelector from "@/hooks/chartsSelector";

import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import Slider from "primevue/slider";

import {
  IColorOption,
  IPlayerData,
  IPlayerDataProductDetails,
  IProduct,
  SidebarOption,
} from "@/interfaces";
import useThemeSettings from "@/hooks/themeSettings";
const __default__ = defineComponent({
  name: "NameSizeTab",
  emits: ["playerDetailsChanged", "quantityChanged", "detailsChanged"],
  components: {
    InputText,
    Slider,
    Dropdown,
    Button,
    InputMask,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const productCategoryPdfs: any = inject("productCategoryPdfs");
    const sizingPdfUrl = productCategoryPdfs.value["sizing"];

    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);

    const providedDetails: Ref<IPlayerDataProductDetails> | undefined =
      inject("productDetails");
    if (!providedDetails) throw new Error("Provided details are undefined");
    const colorOptions: IColorOption[] | undefined = inject("colorOptions");
    const minQty: Ref<number> | undefined = inject("minimumQuantity");
    if (!minQty) throw new Error("Min qty is undefined in Name&Size component");
    const fontFamily = ref("");
    const fontColor = ref({ value: "#fff", name: "White" });
    fontColor.value = providedDetails.value.fontColor;
    fontFamily.value = providedDetails.value.fontFamily;
    const teamName: Ref<IPlayerDataProductDetails["teamName"]> = ref("");
    const teamNameSize: Ref<IPlayerDataProductDetails["teamNameSize"]> =
      ref(null);
    teamName.value = providedDetails.value.teamName;
    teamNameSize.value = providedDetails.value.teamNameSize ?? 17;

    const activeProduct: Ref<IProduct> | undefined = inject("activeProduct");
    const productType: string | undefined = inject("activeProductType");
    if (!productType) throw new Error("Product type is undef");

    const chartFileName = useChartsSelector(productType);

    const chartRequired = computed(() => {
      return chartFileName.value !== null;
    });
    const teamNameSizeElementRequired = computed(() => {
      return (
        productType === "basketball_jerseys" &&
        (!activeProduct ||
          (activeProduct &&
            activeProduct.value.alias !== "basketball_jerseys_7"))
      );
    });
    const playerNumberFrontSizeRequired = computed(() => {
      return (
        productType === "basketball_jerseys" &&
        (!activeProduct ||
          (activeProduct &&
            activeProduct.value.alias !== "basketball_jerseys_7"))
      );
    });

    const nameBackVerticalPositionRequired = computed(() => {
      return productType !== "basketball_jerseys";
    });
    const fonts: Ref<Array<string>> = ref([
      "Arial",
      "Adelle",
      "ArialRounded",
      "Calibri",
      "College",
      "Impact",
    ]);

    const playersDetails: Ref<Array<IPlayerData>> = ref([
      {
        id: 0,
        name: "",
        nameSize: 10,
        nameBackVerticalPosition: 50,
        number: "0",
        size: null,
        quantity: minQty,
        viewedNow: true,
        playerNumberBackSize: 70,
        playerNumberFrontSize: 50,
        playerNumberBackVerticalPosition: null,
      },
    ]);
    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );
    const playersData: Ref<Array<IPlayerData>> | undefined =
      inject("playersData");
    if (!playersData) throw new Error("Players data is undefined");
    const availableSizes: Ref<{ id: number; name: string }[]> | undefined =
      inject("availableSizes");
    if (!availableSizes) throw new Error("Availables sizes is undefined");
    const onDataChange = (index: number) => {
      const previousActiveElement = playersDetails.value.find(
        (el) => el.viewedNow === true
      );
      if (previousActiveElement) {
        previousActiveElement.viewedNow = false;
      }
      if (playersDetails.value[index]) {
        playersDetails.value[index].viewedNow = true;
      } else {
        playersDetails.value[0].viewedNow = true;
      }
      emit("playerDetailsChanged", playersDetails.value);
    };
    if (!playersData.value.length) {
      let playersDetailsArray = [];

      for (let i = 0; i < minQty.value; i++) {
        playersDetailsArray.push({
          id: i,
          name: "",
          nameSize: 30,
          nameBackVerticalPosition: 50,
          number: "",
          size: null,
          quantity: 1,
          viewedNow: true,
          playerNumberFrontSize: 50,
          playerNumberBackSize: 70,
          playerNumberBackVerticalPosition: null,
        });
      }

      playersDetails.value = playersDetailsArray;

      onDataChange(0);
    } else {
      playersDetails.value = playersData.value;
    }
    const sizeOptions: Ref<Array<{ id: number; name: string }>> = ref([]);
    sizeOptions.value = availableSizes.value;
    // TODO: USE INJECTED SIZES
    const addNewPlayer = () => {
      const previousActiveElement = playersDetails.value.find(
        (el) => el.viewedNow === true
      );
      if (previousActiveElement) previousActiveElement.viewedNow = false;
      playersDetails.value.push({
        id: playersDetails.value.length,
        name: "",
        nameSize: previousActiveElement?.nameSize || 15,
        nameBackVerticalPosition: 50,
        number: "",
        size: null,
        quantity: 1,
        viewedNow: true,
        playerNumberFrontSize: 50,
        playerNumberBackSize: 70,
        playerNumberBackVerticalPosition: null,
      });
      if (!areAllProductsSamePattern.value) {
        playersDetails.value[0].quantity = 1;
        quantityChanged(0);
      }
    };
    const onDetailsChanged = () => {
      emit("detailsChanged", {
        fontFamily: fontFamily.value,
        fontColor: fontColor.value,
        teamName: teamName.value,
        teamNameSize: teamNameSize.value,
      });
    };
    const onFontColorChange = (newColorData: IColorOption) => {
      fontColor.value = newColorData;
      onDetailsChanged();
    };

    const areAllProductsSamePattern = computed(() => {
      return playersDetails.value.length === 1;
    });
    const removePlayer = (index: number) => {
      if (playersDetails.value.length === 1) return;
      playersDetails.value.splice(index, 1);
      quantityChanged(index);
    };
    const quantityChanged = (index: number) => {
      const quantitySum = playersDetails.value.reduce((sum, currentItem) => {
        return sum + currentItem.quantity;
      }, 0);
      onDataChange(index);
      emit("quantityChanged", quantitySum);
    };
    return {
      sizingPdfUrl,
      accentColor,
      accentColorHover,
      activeSidebarOption,
      fonts,
      baseUrl,
      teamName,
      teamNameSize,
      fontColor,
      fontFamily,
      sizeOptions,
      activeProduct,
      productType,
      teamNameSizeElementRequired,
      addNewPlayer,
      chartRequired,
      chartFileName,
      colorOptions,
      onDataChange,
      removePlayer,
      playersDetails,
      quantityChanged,
      onDetailsChanged,
      onFontColorChange,
      areAllProductsSamePattern,
      playerNumberFrontSizeRequired,
      nameBackVerticalPositionRequired,
      minQty,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "19b67ad2": (_ctx.accentColor),
  "21ca518a": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__