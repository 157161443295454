
import { defineComponent, ref, inject, computed } from "vue";
import type { Ref } from "vue";
import { getFontSizeForWrapped, isDeviantFont } from "@/logic/textResize";

import InputText from "primevue/inputtext";
import RadioButton from "primevue/radiobutton";
// import Slider from "primevue/slider";
import Dropdown from "primevue/dropdown";
import { IAddon, IColorOption, ITextData, SidebarOption } from "@/interfaces";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "TextTab",
  emits: ["textChanged", "addonsChanged"],
  components: {
    InputText,
    Dropdown,
    // Slider,
    RadioButton,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const textData: Ref<ITextData> | undefined = inject("textData");
    if (textData === undefined) throw new Error("textData is undefined");
    const colorOptions: IColorOption[] | undefined = inject("colorOptions");
    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );
    const activeProductType = inject("activeProductType");
    if (!activeProductType)
      throw new Error("Active product in text component undefined");
    const textType: Ref<string | null> = ref("front");
    const productAddons: Ref<IAddon[]> | undefined = inject("selectedAddons");
    if (!productAddons) throw new Error("products addons are undefined");

    const text_1: Ref<string | null> = ref(null);
    const text_2: Ref<string | null> = ref(null);
    const maxChars: Ref<number> = ref(0);
    const fontColor: Ref<{ value: string; name: string } | null> = ref(null);
    const fontSize: Ref<number | null> = ref(null);
    const fontFamily: Ref<string | null> = ref(null);
    const backSideText: Ref<{ message: string }> = ref({ message: "" });

    const fonts: Ref<Array<string>> = ref([
      "Arial",
      // "ArialBoldMT",
      // "AdelleBoldItalic",
      "Adelle",
      // "AdelleBold",
      // "AdelleItalic",
      // "AdelleLight",
      // "AdelleLightItalic",
      // "AdelleSemiBold",
      // "AdelleSemiBoldItalic",
      "ArialRounded",
      "Calibri",
      // "CalibriBold",
      // "CalibriBoldItalic",
      // "CalibriItalic",
      // "CalibriLight",
      // "CalibriLightItalic",
      "College",
      // "CollegeBold",
      "Impact",
      // "Segoe",
    ]);

    if (textData.value) {
      text_1.value = textData.value.message;
      text_2.value = textData.value.message2;
      fontColor.value = textData.value.color;
      fontFamily.value = textData.value.font;
      textType.value = textData.value.type;
      fontSize.value = textData.value.size;
      maxChars.value = textData.value.max_chars;
      backSideText.value = textData.value.back_side_text || {
        message: textData.value.message,
      };
    }
    const changeColor = (color: { value: string; name: string }) => {
      fontColor.value = color;
      onTextChange({ textAdjust: false });
    };

    const initialTextParams = {
      length: textData.value ? textData.value.message.length : null,
      size: textData.value ? textData.value.size : null,
    };
    const resizeBeanie = computed(() => {
      const text = text_1.value;
      if (!text) return initialTextParams.size;
      const textLength = text.length;
      if (textType.value === "wrapped") {
        return getFontSizeForWrapped(textLength, fontFamily.value);
      }
      if (isDeviantFont(fontFamily.value)) {
        if (textLength <= 6) {
          return initialTextParams.size;
        }
        if (textLength <= 8) {
          return 330;
        }
        if (textLength <= 10) {
          return 250;
        }
        if (textLength < 12) {
          return 220;
        }
        if (text.includes(" ")) {
          return textData.value.size;
        }
        return 200;
      }
      if (textLength <= 6) return initialTextParams.size;
      if (textLength <= 8) return 360;
      if (textLength <= 10) return 280;
      if (textLength < 12) return 240;
      if (text.includes(" ")) return textData.value.size;
      return 230;
    });
    const resizeScarf = computed(() => {
      let text = text_1.value;
      // if (text_1.value && text_2.value) {
      //   const isText1Bigger = text_1.value.length > text_2.value.length;
      //   if (isText1Bigger) return;
      //   text = text_2.value;
      // }
      if (!text) return initialTextParams.size;
      const textLength = text.length;
      if (textLength >= 3 && textLength <= 4) return 160;
      if (textLength === 5) return 133;
      if (textLength >= 6) return 90;
      return 250;
    });
    const onAddonChange = () => {
      const addonIndexInSelectedAddons = productAddons.value.findIndex(
        (el) => el.id === 1 // (Woven text addon ID is '1')
      );
      const addon = { id: 1, name: "woven text on back", price: 0.5 };
      // EMIT EVENT AND ADD ADDON TO PRODUCT ADDONS ARRAY
      if (textType.value !== "front" && addonIndexInSelectedAddons === -1) {
        backSideText.value.message = text_1.value ? text_1.value : "";
        emit("addonsChanged", addon);
      }
      // EMIT EVENT AND REMOVE ADDON FROM PRODUCT ADDONS ARRAY
      else if (textType.value === "front" && addonIndexInSelectedAddons >= 0) {
        backSideText.value.message = "";
        emit("addonsChanged", addon);
      }
      onTextChange({ textAdjust: true });
    };
    const onTextChange = (data: { textAdjust: boolean }) => {
      if (text_1.value && fontSize.value) {
        let formattedText = text_1.value.toUpperCase();
        // RESIZING
        if (activeProductType === "beanie") {
          fontSize.value = data.textAdjust
            ? resizeBeanie.value
            : fontSize.value;
        }
        if (activeProductType === "scarf" && maxChars.value === 7) {
          fontSize.value = data.textAdjust ? resizeScarf.value : fontSize.value;
        }
        //
        // SLICING LONGER TEXT AFTER SWITCH FROM WRAPPING TO ANY OTHER
        if (formattedText.length > 12 && textType.value !== "wrapped") {
          formattedText = formattedText.slice(0, 12);
          text_1.value = formattedText;
        }
        //
        // if (formattedText.length > 12 && textType.value === "wrapped") {
        //   const symbolsToRemoveFromFrontSide = formattedText.length - 12;
        //   const backLeftSymbolsCount = Math.floor(
        //     symbolsToRemoveFromFrontSide / 2
        //   );
        //   const backRightSymbolsCount = Math.round(
        //     symbolsToRemoveFromFrontSide / 2 + 0.1
        //   );
        //   const rightBackText = formattedText.substring(
        //     0,
        //     backRightSymbolsCount
        //   );
        //   const leftBackText = formattedText.slice(-backLeftSymbolsCount);
        //   backSideText.value.message = `${leftBackText} ${rightBackText}`;
        // }
        let textObj = {
          message: formattedText,
          message2: text_2.value ? text_2.value.toUpperCase() : null,
          color: fontColor.value,
          font: fontFamily.value,
          type: textType.value,
          size: fontSize.value,
          back_side_text: {
            message: backSideText.value
              ? backSideText.value.message.toUpperCase()
              : null,
          },
        };
        emit("textChanged", textObj);
      }
    };

    return {
      accentColor,
      accentColorHover,
      activeSidebarOption,
      textData,
      textType,
      text_1,
      text_2,
      maxChars,
      fontColor,
      fontFamily,
      fonts,
      fontSize,
      colorOptions,
      onTextChange,
      onAddonChange,
      changeColor,
      backSideText,
      activeProductType,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "060eccea": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__