<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M6.5,11L12,2L17.5,11L6.5,11ZM17.5,22C16.25,22 15.188,21.563 14.313,20.688C13.438,19.813 13,18.75 13,17.5C13,16.25 13.438,15.188 14.313,14.313C15.188,13.438 16.25,13 17.5,13C18.75,13 19.813,13.438 20.688,14.313C21.563,15.188 22,16.25 22,17.5C22,18.75 21.563,19.813 20.688,20.688C19.813,21.563 18.75,22 17.5,22ZM3,21.5L3,13.5L11,13.5L11,21.5L3,21.5ZM17.5,20C18.2,20 18.792,19.758 19.275,19.275C19.758,18.792 20,18.2 20,17.5C20,16.8 19.758,16.208 19.275,15.725C18.792,15.242 18.2,15 17.5,15C16.8,15 16.208,15.242 15.725,15.725C15.242,16.208 15,16.8 15,17.5C15,18.2 15.242,18.792 15.725,19.275C16.208,19.758 16.8,20 17.5,20ZM5,19.5L9,19.5L9,15.5L5,15.5L5,19.5ZM10.05,9L13.95,9L12,5.85L10.05,9Z"
      :style="{
        fillRule: 'nonzero',
        fill: active ? '#ffffff' : defaultAccentColor,
      }"
    />
  </svg>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "TemplateIcon",
  props: ["isActive", "element", "accentColor"],
  setup(props) {
    const hover = ref(false);
    const active = computed(
      () => props.isActive === true || props.element.hover === true
    );
    const defaultAccentColor = computed(() =>
      props.accentColor ? props.accentColor : "#ff7434"
    );
    return { active, hover, defaultAccentColor };
  },
};
</script>
