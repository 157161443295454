
import { defineComponent } from "vue";
import Header from "../components/Header.vue";
import Button from "primevue/button";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "404View",
  components: {
    Header,
    Button,
  },
  setup() {
    const router = useRouter();
    const goHome = () => {
      router.push({ name: "home" });
    };
    return {
      goHome,
    };
  },
});
