
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Skeleton from "primevue/skeleton";
import InputNumber from "primevue/inputnumber";

import { useRoute, useRouter } from "vue-router";
import { defineComponent, computed, ref, inject } from "vue";
import { useCartStore } from "@/stores/CartStore";
import { ICartItem, IPlayerData, IProduct } from "@/interfaces";
import type { Ref } from "vue";
import Decimal from "decimal.js";

import { v4 as uuid } from "uuid";

import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFlip, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";
import useProductSettings from "@/hooks/productSettings";

import * as productsSvgs from "@/components/SVGImagesAsComponents/index";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import useProductDesign from "@/hooks/productDesign";
import useFlashNotifications from "@/hooks/flashNotifications";
import useCart from "@/hooks/cart";
import useThemeSettings from "@/hooks/themeSettings";
import usePricingTableSelector from "@/hooks/pricingTableSelector";

const __default__ = defineComponent({
  name: "PreviewPanel",
  components: {
    Button,
    Dropdown,
    Skeleton,
    InputNumber,
    Dialog,
    InputText,

    Swiper,
    SwiperSlide,

    ...productsSvgs,
  },
  emits: ["quantityChanged", "designSaved"],
  setup(props, { emit }) {
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const productCategoryPdfs: any = inject("productCategoryPdfs");
    const pricingPdfUrl = productCategoryPdfs.value["pricing"];

    const { showWarning } = useFlashNotifications();
    const router = useRouter();
    const route = useRoute();
    const cart = useCartStore();
    const priceLoading = inject("priceLoading");

    const productSettings: Ref<{ productionTime: string }> | undefined =
      inject("productSettings");
    if (!productSettings) throw new Error("Product settings is undefined");
    const minPrice: Ref<number | null> | undefined = inject("minimumPrice");
    const minQty: Ref<number | null> | undefined = inject("minimumQuantity");
    if (!minQty) throw new Error("min quantity is undefined");
    const activeProduct: Ref<IProduct> | undefined = inject("activeProduct");
    if (!activeProduct) throw new Error("activeProduct is undefined");

    // WORKING VERSION
    const {
      v$,
      userData,
      loadingDesignRequest,
      openSaveDesignDialog,
      saveDesign,
      spinnerComputed,
    } = useProductDesign(activeProduct, emit);

    const activeProductAlias = computed(() => activeProduct.value.alias);
    const buttonLabel = computed(() => {
      if (activeProduct.value.id) {
        return "SAVE";
      }
      return "ADD TO CART";
    });
    const activePlayerView = computed(() => {
      if (activeProduct.value.playersDetails) {
        const item = activeProduct.value.playersDetails.find(
          (el: IPlayerData) => el.viewedNow === true
        );
        return item;
      }
      return null;
    });
    const isQuantityEditingDisabled = computed(() => {
      if (
        activeProduct.value.type === "t_shirt" ||
        activeProduct.value.type === "basketball_jerseys"
      )
        return true;
      return false;
    });

    const { submitProduct } = useCart(activeProduct);

    const selectedProduct = ref();
    const allProducts = ref([
      { name: "Beanie", value: "beanie" },
      { name: "T-Shirt", value: "t_shirt" },
      { name: "Hoodie", value: "cuthoodie" },
      { name: "Scarf", value: "scarf" },
    ]);
    const currentProductToSelect = allProducts.value.find(
      (el) => el.value === route.params.product
    );
    selectedProduct.value = currentProductToSelect;

    // Manipulate activeProduct instead selectedQuantity creation?
    // const selectedQuantity = ref(activeProduct.value.quantity);
    // if (!activeProduct.value.quantity && minQty.value) {
    //   selectedQuantity.value = minQty.value as number;
    // }
    // if (activeProduct.value.quantity) {
    //   selectedQuantity.value = activeProduct.value.quantity;
    // }
    const quantityChanged = (e: any) => {
      emit("quantityChanged", e);
    };

    const price = computed(() => {
      if (!activeProduct.value.price) return;
      const addonsPrice = activeProduct.value.addons.reduce(
        (sum, currentItem) => {
          let price = currentItem && currentItem.price ? currentItem.price : 0;
          return sum + price;
        },
        0
      );
      const result =
        (activeProduct.value.quantity || 1) *
        (activeProduct.value.price + addonsPrice);
      return new Decimal(result).toFixed(2);
    });
    const pricingFileName = usePricingTableSelector(activeProduct.value.type);
    return {
      modules: [EffectFlip, Pagination, Navigation],

      pricingPdfUrl,

      accentColor,
      accentColorHover,

      loadingDesignRequest,
      userData,
      v$,
      saveDesign,
      spinnerComputed,
      openSaveDesignDialog,
      minQty,
      priceLoading,
      buttonLabel,
      selectedProduct,
      allProducts,
      activePlayerView,
      productSettings,
      price,
      activeProductAlias,
      activeProduct,
      submitProduct,
      quantityChanged,
      isQuantityEditingDisabled,
      minPrice,
      pricingFileName,
      baseUrl,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7026d1fe": (_ctx.accentColor),
  "058d0a8a": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__