<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M12,22C10.633,22 9.342,21.738 8.125,21.212C6.908,20.688
    5.846,19.971 4.938,19.063C4.029,18.154 3.313,17.092
    2.788,15.875C2.263,14.658 2,13.367 2,12C2,10.617 2.271,9.317
    2.813,8.1C3.354,6.883 4.088,5.825 5.013,4.925C5.938,4.025 7.017,3.313
    8.25,2.787C9.483,2.262 10.8,2 12.2,2C13.533,2 14.792,2.229
    15.975,2.688C17.158,3.146 18.196,3.779 19.088,4.587C19.979,5.396
    20.688,6.354 21.213,7.462C21.738,8.571 22,9.767 22,11.05C22,12.967
    21.417,14.438 20.25,15.463C19.083,16.488 17.667,17 16,17L14.15,17C14,17
    13.896,17.042 13.838,17.125C13.779,17.208 13.75,17.3 13.75,17.4C13.75,17.6
    13.875,17.888 14.125,18.263C14.375,18.638 14.5,19.067 14.5,19.55C14.5,20.383
    14.271,21 13.813,21.4C13.354,21.8 12.75,22 12,22ZM6.5,13C6.933,13
    7.292,12.858 7.575,12.575C7.858,12.292 8,11.933 8,11.5C8,11.067 7.858,10.708
    7.575,10.425C7.292,10.142 6.933,10 6.5,10C6.067,10 5.708,10.142
    5.425,10.425C5.142,10.708 5,11.067 5,11.5C5,11.933 5.142,12.292
    5.425,12.575C5.708,12.858 6.067,13 6.5,13ZM9.5,9C9.933,9 10.292,8.858
    10.575,8.575C10.858,8.292 11,7.933 11,7.5C11,7.067 10.858,6.708
    10.575,6.425C10.292,6.142 9.933,6 9.5,6C9.067,6 8.708,6.142
    8.425,6.425C8.142,6.708 8,7.067 8,7.5C8,7.933 8.142,8.292
    8.425,8.575C8.708,8.858 9.067,9 9.5,9ZM14.5,9C14.933,9 15.292,8.858
    15.575,8.575C15.858,8.292 16,7.933 16,7.5C16,7.067 15.858,6.708
    15.575,6.425C15.292,6.142 14.933,6 14.5,6C14.067,6 13.708,6.142
    13.425,6.425C13.142,6.708 13,7.067 13,7.5C13,7.933 13.142,8.292
    13.425,8.575C13.708,8.858 14.067,9 14.5,9ZM17.5,13C17.933,13 18.292,12.858
    18.575,12.575C18.858,12.292 19,11.933 19,11.5C19,11.067 18.858,10.708
    18.575,10.425C18.292,10.142 17.933,10 17.5,10C17.067,10 16.708,10.142
    16.425,10.425C16.142,10.708 16,11.067 16,11.5C16,11.933 16.142,12.292
    16.425,12.575C16.708,12.858 17.067,13 17.5,13ZM12,20C12.15,20 12.271,19.958
    12.363,19.875C12.454,19.792 12.5,19.683 12.5,19.55C12.5,19.317 12.375,19.042
    12.125,18.725C11.875,18.408 11.75,17.933 11.75,17.3C11.75,16.6 11.992,16.042
    12.475,15.625C12.958,15.208 13.55,15 14.25,15L16,15C17.1,15 18.042,14.679
    18.825,14.038C19.608,13.396 20,12.4 20,11.05C20,9.033 19.229,7.354
    17.688,6.012C16.146,4.671 14.317,4 12.2,4C9.933,4 8,4.775
    6.4,6.325C4.8,7.875 4,9.767 4,12C4,14.217 4.779,16.104
    6.338,17.663C7.896,19.221 9.783,20 12,20Z"
      :style="{
        fillRule: 'nonzero',
        fill: active ? '#ffffff' : defaultAccentColor,
      }"
    />
  </svg>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "ColorsIcon",
  props: ["isActive", "element", "accentColor"],
  setup(props) {
    const hover = ref(false);
    const active = computed(
      () => props.isActive === true || props.element.hover === true
    );
    const defaultAccentColor = computed(() =>
      props.accentColor ? props.accentColor : "#ff7434"
    );
    return { active, hover, defaultAccentColor };
  },
};
</script>
