import { Ref, ref, shallowRef } from "vue";
import { SidebarOption } from "@/interfaces";
import ColorsIcon from "@/components/SVGImagesAsComponents/Sidebar/ColorsIcon.vue";
import TemplateIcon from "@/components/SVGImagesAsComponents/Sidebar/TemplateIcon.vue";
import TextIcon from "@/components/SVGImagesAsComponents/Sidebar/TextIcon.vue";
import LogoIcon from "@/components/SVGImagesAsComponents/Sidebar/LogoIcon.vue";
export const currentTab = ref({ id: "templateTab" });
export const sidebarOptions: Ref<Array<SidebarOption>> = ref([
  {
    visible: false,
    stepNumber: 0,
    name: "TEMPLATE",
    id: "templateTab",
    active: true,
    hover: false,
    icon: shallowRef(TemplateIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "COLOURS",
    id: "colorTab",
    active: false,
    hover: false,
    icon: shallowRef(ColorsIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "TEXT",
    id: "textTab",
    active: false,
    hover: false,
    icon: shallowRef(TextIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "LOGO",
    id: "logoTab",
    active: false,
    hover: false,
    icon: shallowRef(LogoIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "LOGO",
    id: "multipleLogoTab",
    active: false,
    hover: false,
    icon: shallowRef(LogoIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "NAMES & SIZES",
    id: "nameSizeTab",
    active: false,
    hover: false,
    icon: shallowRef(TextIcon),
  },
  {
    visible: false,
    stepNumber: 0,
    name: "SIZE",
    id: "sizeTab",
    active: false,
    hover: false,
    icon: shallowRef(TextIcon),
  },
  // {
  //   name: "EXTRAS",
  //   id: "extrasTab",
  //   active: false,
  //   icon: "extras",
  // },
]);
const getProductType = (type: string): string => {
  if (
    type === "t_shirt" ||
    type === "longsleeve" ||
    type === "singlet" ||
    type === "polo" ||
    type === "subhoodie" ||
    type === "basketball_jerseys"
  ) {
    return "PlayerDataProduct";
  }
  if (type === "cuthoodie" || type === "basketball_shorts") {
    return "SizeOnly";
  }
  if (type === "crewsock") {
    return "Socks";
  }
  if (type === "truckercap") {
    return "NoText";
  }
  return "Basic";
};
export const setSidebarOptions = (productType: string) => {
  const type = getProductType(productType);
  if (type === "PlayerDataProduct") {
    sidebarOptions.value = sidebarOptions.value.map((sidebarItem) => {
      sidebarItem.visible =
        sidebarItem.name !== "TEXT" &&
        sidebarItem.name !== "SIZE" &&
        sidebarItem.id !== "logoTab";
      return sidebarItem;
    });
  }
  if (type === "SizeOnly") {
    sidebarOptions.value = sidebarOptions.value.map((sidebarItem) => {
      sidebarItem.visible =
        sidebarItem.name !== "TEXT" &&
        sidebarItem.name !== "NAMES & SIZES" &&
        sidebarItem.id !== "multipleLogoTab";
      return sidebarItem;
    });
  }
  if (type === "NoText") {
    sidebarOptions.value = sidebarOptions.value.map((sidebarItem) => {
      sidebarItem.visible =
        sidebarItem.name !== "TEXT" &&
        sidebarItem.name !== "NAMES & SIZES" &&
        sidebarItem.name !== "SIZE" &&
        sidebarItem.id !== "multipleLogoTab";
      return sidebarItem;
    });
  }
  if (type === "Socks") {
    sidebarOptions.value = sidebarOptions.value.map((sidebarItem) => {
      sidebarItem.visible =
        sidebarItem.name !== "TEXT" &&
        sidebarItem.name !== "NAMES & SIZES" &&
        sidebarItem.id !== "multipleLogoTab";
      return sidebarItem;
    });
  }
  if (type === "Basic") {
    sidebarOptions.value = sidebarOptions.value.map((sidebarItem) => {
      sidebarItem.visible =
        sidebarItem.name !== "SIZE" &&
        sidebarItem.name !== "NAMES & SIZES" &&
        sidebarItem.id !== "multipleLogoTab";
      return sidebarItem;
    });
  }

  let sidebarItemsIndex = 0;
  sidebarOptions.value.forEach((item, index) => {
    if (item.visible === true) {
      sidebarItemsIndex += 1;
      item.stepNumber = sidebarItemsIndex;
    }
  });
};
export const changeTab = (el: SidebarOption) => {
  const oldActiveTabIndex = sidebarOptions.value.findIndex(
    (element) => element.active === true
  );
  sidebarOptions.value[oldActiveTabIndex].active = false;
  const newActiveTabIndex = sidebarOptions.value.findIndex(
    (element) => element === el
  );
  sidebarOptions.value[newActiveTabIndex].active = true;
  currentTab.value.id = sidebarOptions.value[newActiveTabIndex].id;
};
export const switchToNextTab = (step: number) => {
  const oldActiveTabIndex = sidebarOptions.value.findIndex(
    (element) => element.active === true
  );
  changeTab(sidebarOptions.value[oldActiveTabIndex + step]);
};
export const getActiveSidebarOption = () => {
  return sidebarOptions.value.find((option) => option.active === true);
};
