
import { defineComponent, onMounted, Ref, ref } from "vue";
import Header from "../components/Header.vue";
import Button from "primevue/button";
import { useRoute, useRouter } from "vue-router";
import { useCartStore } from "@/stores/CartStore";
import { API } from "@/api";
import { IOrderedItem } from "@/interfaces";
import { useGtm } from "@gtm-support/vue-gtm";
import { useCompanyStore } from "@/stores/CompanyStore";

export default defineComponent({
  name: "PaymnetSuccess",
  components: {
    Header,
    Button,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const cart = useCartStore();
    const gtm = useGtm();
    if (!route.query.order_id) throw new Error("Order id is not specified");
    if (!route.query.name) throw new Error("Order firstname is not specified");
    onMounted(() => {
      cart.clearCart();
    });

    const orderId = route.query.order_id;

    if (orderId) {
      const companyStore = useCompanyStore();
      const companyInfo: any = companyStore.getCompanyInfo;
      if (!companyInfo) {
        throw new Error("Company not found");
      }

      const api = new API();
      api.getOrder(companyInfo.id, orderId as string).then((response) => {
        if (gtm) {
          const orderInfo = response.data.data;
          const itemsInfo = response.data.data.items;

          let gtmItems: any = [];
          itemsInfo.forEach((item: any, index: number) => {
            gtmItems.push({
              item_id: item.productID,
              item_name: item.product,
              index: index,
              item_brand: "Cheeta",
              item_category: item.categoryAlias,
              price: item.price,
              quantity: item.quantity,
            });
          });

          gtm.trackEvent({
            event: "purchase",
            category: "purchase_success",
            action: "view",
            transaction_id: orderId,
            value: orderInfo.amountWithoutGst, // total order value ex GST
            tax: orderInfo.gst, // GST on order
            shipping: orderInfo.shipping, // shipping cost ex GST
            currency: "AUD",
            items: gtmItems,

            noninteraction: false,
          });
        }
      });
    }

    const goBlueprint = () => {
      router.push({ name: "blueprint", query: { id: orderId } });
    };
    const goHome = () => {
      router.push({ name: "home" });
    };
    return {
      goHome,
      goBlueprint,
    };
  },
});
