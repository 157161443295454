
import { defineComponent, computed, ref, provide, onMounted } from "vue";
import type { Ref } from "vue";
import { useCartStore } from "@/stores/CartStore";

import Header from "../components/Header.vue";
import OrderDetails from "../components/Checkout/OrderDetails.vue";
import UserInfoPanel from "@/components/Checkout/UserInfoPanel.vue";

import { API } from "@/api";
import {
  ICartItem,
  ILogoObject,
  IOrderUserData,
  ITextData,
} from "@/interfaces";
import { useRouter } from "vue-router";
import useFlashNotifications from "@/hooks/flashNotifications";
import Toast from "primevue/toast";
import { useCompanyStore } from "@/stores/CompanyStore";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "CheckoutView",
  components: {
    Header,
    UserInfoPanel,
    OrderDetails,
    Toast,
  },
  setup() {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const api = new API();
    const router = useRouter();
    const cart = useCartStore();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }
    const { showError } = useFlashNotifications();
    const selectedPostcode: Ref<number | null> = ref(null);
    const postcodeSelected = (e: number) => {
      selectedPostcode.value = e;
      fetchShippingPrice();
    };
    const shippingPrice: Ref<"loading" | number | null> = ref(null);
    const fetchShippingPrice = async () => {
      if (!selectedPostcode.value) return;
      shippingPrice.value = "loading";
      const quantity = orderedItems.value.reduce((sum, currentItem) => {
        return sum + currentItem.quantity;
      }, 0);
      const response = await api.getShippingPrice(
        1,
        selectedPostcode.value,
        quantity
      );
      shippingPrice.value = response;
    };
    const postcodes = ref([]);
    const postcodesLoading = ref(true);
    const statesArray = ref([]);
    onMounted(async () => {
      const fetchedPostcodes = await api.getPostcodes();
      const fetchedStates = await api.getStates();
      statesArray.value = fetchedStates.data;
      postcodes.value = fetchedPostcodes.data.map((element: string) => {
        return { value: element };
      });
      postcodesLoading.value = false;
    });
    const loading = ref(false);
    const orderedItems = computed(() => {
      return cart.getCart;
    });
    const cartHash = cart.getCartHash;
    const isLogoObject = (obj: any): obj is ILogoObject => {
      return "path" in obj && "size" in obj;
    };
    const formDataForRequest = new FormData();
    orderedItems.value.forEach(async (element, index) => {
      formDataForRequest.append(
        `products[${index}][id]`,
        element.databaseId.toString()
      );
      formDataForRequest.append(
        `products[${index}][quantity]`,
        element.quantity.toString()
      );
      const addonsIdsList = element.addons.map((el) => el.id.toString());
      addonsIdsList.forEach((element, elIndex) => {
        formDataForRequest.append(
          `products[${index}][addons][${elIndex}]`,
          element.toString()
        );
      });
      formDataForRequest.append(
        `products[${index}][imageBack]`,
        new File(
          [
            new Blob([element.imageBack], {
              type: "image/svg+xml;charset=utf-8",
            }),
          ],
          "imageBack.svg",
          { type: "image/svg+xml" }
        )
      );
      formDataForRequest.append(
        `products[${index}][imageFront]`,
        new File(
          [
            new Blob([element.imageFront], {
              type: "image/svg+xml;charset=utf-8",
            }),
          ],
          "imageFront.svg",
          {
            type: "image/svg+xml",
          }
        )
      );
      formDataForRequest.append(
        `products[${index}][details]`,
        JSON.stringify({
          colors: element.colors,
          text: element.text as ITextData,
          addons: element.addons,
          details: element.details,
          playersDetails: element.playersDetails || [],
          sizes: element.sizes || [],
          cheetaLogoData: element.cheetaLogo,
        })
      );
      if (isLogoObject(element.logo) && element.logo.path !== "") {
        const base64Data = element.logo.path;
        const base64Response = await fetch(base64Data);
        const blob = await base64Response.blob();
        formDataForRequest.append(
          `products[${index}][logo][image]`,
          new File([blob], "logo.png")
        );
        formDataForRequest.append(
          `products[${index}][logo][size]`,
          element.logo.size.toString()
        );
      } else if (!isLogoObject(element.logo)) {
        Object.entries(element.logo).forEach(
          async ([key, value], indexOfItem) => {
            if (!value) return;
            const base64Data = value.path;
            const base64Response = await fetch(base64Data);
            const blob = await base64Response.blob();
            // CAPITALIZE FIRST LETTER IN A KEY
            const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
            formDataForRequest.append(
              `products[${index}][logo${formattedKey}][image]`,
              new File([blob], `${formattedKey}.png`)
            );
            formDataForRequest.append(
              `products[${index}][logo${formattedKey}][size]`,
              value.size
            );
          }
        );
      }
    });

    const onSubmit = (e: IOrderUserData, paymentOption: string) => {
      formDataForRequest.append("cartId", cartHash);
      formDataForRequest.append("billingDetails[email]", e.email);
      formDataForRequest.append("billingDetails[phone]", e.phone);
      formDataForRequest.append("billingDetails[name]", e.firstName);
      formDataForRequest.append("billingDetails[lastName]", e.lastName);
      formDataForRequest.append("billingDetails[businessName]", e.businessName);
      formDataForRequest.append("billingDetails[state]", e.address.state);
      formDataForRequest.append("billingDetails[street]", e.address.details);
      formDataForRequest.append("billingDetails[suburb]", e.address.suburb);
      formDataForRequest.append(
        "billingDetails[postcode]",
        e.postcode.toString()
      );

      if (e.artwork && e.artwork.length > 0) {
        let artworkIndex = 0;
        e.artwork.forEach(function (item) {
          formDataForRequest.append(`artwork[${artworkIndex}]`, item);
          artworkIndex++;
        });
      }

      if (paymentOption === "Invoice")
        formDataForRequest.append("instantPayment", "0");
      loading.value = true;
      api
        .checkout(companyInfo.id, formDataForRequest)
        .then((resp) => {
          loading.value = false;
          if (paymentOption === "Card") {
            window.location.assign(resp.link);
          } else {
            router.push({
              name: "order-success",
              query: {
                alias: resp.alias,
                order_id: resp.order,
                name: e.firstName,
              },
            });
          }
        })
        .catch((e) => {
          showError("Something went wrong. If the issue repeats contact us.");
          loading.value = false;
        });
    };
    const editDesign = (product: ICartItem) => {
      router.push({
        name: "constructor",
        params: { product: product.type },
        query: { id: product.id },
      });
    };

    provide("orderedItems", orderedItems);
    provide("statesArray", statesArray);
    provide(
      "postcodes",
      computed(() => postcodes.value)
    );
    provide(
      "postcodesLoading",
      computed(() => postcodesLoading.value)
    );
    provide(
      "shippingPriceState",
      computed(() => shippingPrice.value)
    );

    return {
      postcodes,
      onSubmit,
      orderedItems,
      postcodeSelected,
      loading,
      editDesign,
      accentColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "d2a95166": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__