
import { computed, defineComponent, inject, ref, Ref } from "vue";
import { ITemplateData, IAddon, IProduct } from "@/interfaces";
import Checkbox from "primevue/checkbox";
import useThemeSettings from "@/hooks/themeSettings";

import * as productsSvgs from "@/components/SVGImagesAsComponents/index";

const __default__ = defineComponent({
  name: "TemplateTab",
  emits: ["templateChanged", "addonsChanged"],
  components: {
    ...productsSvgs,
    Checkbox,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const productTemplates: ITemplateData[] | undefined =
      inject("productTemplates");
    const activeProduct: Ref<IProduct> | undefined = inject("activeProduct");
    if (!activeProduct)
      throw new Error("Active product is undefined in templates tab");
    const chinStrapAddonState: Ref<boolean> = ref(false);
    const availableAddons: Ref<IAddon[]> | undefined =
      inject("availableAddons");
    if (!availableAddons)
      throw new Error("Available addons are undefined (LOGO TAB)");
    const chinStrapAddon = computed(() => {
      const addon = availableAddons.value.find((el) => el.name == "chin strap");
      return addon ? addon : null;
    });

    const onChinStrapCheckboxChange = () => {
      emit("addonsChanged", chinStrapAddon.value);
    };

    const changeActiveTemplate = (item: ITemplateData) => {
      if (productTemplates) {
        const newTemplateId = `${item.product}_${item.id}`;
        emit("templateChanged", newTemplateId);
      }
      return;
    };

    return {
      accentColor,
      accentColorHover,
      onChinStrapCheckboxChange,
      chinStrapAddonState,
      chinStrapAddon,
      activeProduct,
      productTemplates,
      changeActiveTemplate,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "15578fbb": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__