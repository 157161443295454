
import { defineComponent, ref, inject } from "vue";
import type { Ref } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { IAddon } from "@/interfaces";
export default defineComponent({
  name: "ExtrasTab",
  emits: ["addonsChanged"],
  components: {
    Accordion,
    AccordionTab,
  },
  setup(props, { emit }) {
    const addons: Ref<Array<IAddon>> | undefined = inject("availableAddons");
    const selectedAddons: Ref<Array<IAddon>> | undefined =
      inject("selectedAddons");
    if (!selectedAddons) throw new Error("Selected addons is undefined");
    const selectAddon = (item: IAddon) => {
      const index = selectedAddons.value.findIndex((el) => el.id === item.id);
      if (index === -1) {
        selectedAddons.value.push(item);
      } else if (index >= 0) {
        selectedAddons.value.splice(index, 1);
      }
      // emit("addonsChanged", selectedAddons.value);
    };
    return {
      addons,
      selectAddon,
      selectedAddons,
    };
  },
});
