
import { defineComponent, ref, Ref, inject, computed } from "vue";

import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

import { ISizeData, SidebarOption } from "@/interfaces";
import useChartsSelector from "@/hooks/chartsSelector";
import useThemeSettings from "@/hooks/themeSettings";
const __default__ = defineComponent({
  name: "NameSizeTab",
  emits: ["sizesDetailsChanged", "quantityChanged"],
  components: {
    Dropdown,
    Button,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const productCategoryPdfs: any = inject("productCategoryPdfs");
    const sizingPdfUrl = productCategoryPdfs.value["sizing"];

    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );

    const minQty: Ref<number> | undefined = inject("minimumQuantity");
    if (!minQty) throw new Error("Min qty is undefined in Size component");
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const productType: string | undefined = inject("activeProductType");
    if (!productType) throw new Error("Product type is undef");
    let forcedMinQty: number | null = null;
    if (productType === "crewsock") {
      forcedMinQty = 12;
    }

    const chartFileName = useChartsSelector(productType);

    const chartRequired = computed(() => {
      return chartFileName.value !== null;
    });

    const providedDetails: Ref<{ fontFamily: string }> | undefined =
      inject("productDetails");
    if (!providedDetails) throw new Error("Provided details are undefined");

    const sizesDetails: Ref<Array<ISizeData>> = ref([
      {
        id: 0,
        size: null,
        quantity: forcedMinQty ? forcedMinQty : minQty.value,
      },
    ]);
    const sizesData: Ref<Array<ISizeData>> | undefined = inject("sizesData");
    if (!sizesData) throw new Error("Players data is undefined");
    const availableSizes: Ref<{ id: number; name: string }[]> | undefined =
      inject("availableSizes");
    if (!availableSizes) throw new Error("Availables sizes is undefined");

    if (!sizesData.value.length) {
      sizesDetails.value = [
        {
          id: 0,
          size: null,
          quantity: forcedMinQty ? forcedMinQty : minQty.value,
        },
      ];
    } else {
      sizesDetails.value = sizesData.value;

      sizesDetails.value.forEach(function (item) {
        if (forcedMinQty) {
          item.quantity = forcedMinQty;
        }
      });
    }
    const sizeOptions: Ref<Array<{ id: number; name: string }>> = ref([]);
    sizeOptions.value = availableSizes.value;
    // TODO: USE INJECTED SIZES
    const addNewPlayer = () => {
      sizesDetails.value.push({
        id: sizesDetails.value.length,
        size: null,
        quantity: forcedMinQty ? forcedMinQty : minQty.value,
      });

      quantityChanged(0);
    };
    const onDataChange = () => {
      emit("sizesDetailsChanged", sizesDetails.value);
    };
    const removePlayer = (index: number) => {
      if (sizesDetails.value.length === 1) return;
      sizesDetails.value.splice(index, 1);
      quantityChanged(index);
    };
    const quantityChanged = (index: number) => {
      const quantitySum = sizesDetails.value.reduce((sum, currentItem) => {
        return sum + currentItem.quantity;
      }, 0);
      onDataChange();
      emit("quantityChanged", quantitySum);
    };
    return {
      sizingPdfUrl,
      activeSidebarOption,
      accentColor,
      accentColorHover,
      baseUrl,
      chartRequired,
      chartFileName,
      sizeOptions,
      addNewPlayer,
      onDataChange,
      removePlayer,
      sizesDetails,
      quantityChanged,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "dfeddb08": (_ctx.accentColorHover),
  "f8892ac0": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__