
import { defineComponent, ref, Ref, computed, inject } from "vue";

import { useVuelidate } from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import { useRouter } from "vue-router";

import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import SelectButton from "primevue/selectbutton";
import Checkbox from "primevue/checkbox";

import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "UserInfoPanel",
  emits: ["submit", "postcodeSelected"],
  props: ["loading"],
  components: {
    FileUpload,
    InputText,
    InputMask,
    Button,
    Dropdown,
    SelectButton,
    Checkbox,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);

    const checkoutFormProcessing = computed(() => props.loading);

    const paymentOption = ref({
      icon: "card",
      description: "Credit card",
      value: "Card",
    });
    const paymentOptions = ref([
      {
        icon: "bank",
        description: "Send me an invoice",
        value: "Invoice",
      },
      {
        icon: "card",
        description: "Credit card",
        value: "Card",
      },
    ]);

    const postcodesArray: Array<string> | undefined = inject("postcodes");
    const postcodesLoading: boolean | undefined = inject("postcodesLoading");
    if (!postcodesArray) throw new Error("Postcodes are undefined");
    const userData = ref({
      firstName: "",
      lastName: "",
      businessName: "",
      email: "",
      phone: "",
      address: {
        details: "",
        suburb: "",
        state: "",
      },
      postcode: "0",
      terms: false,
      artwork: null,
    });

    const rules = {
      firstName: { required },
      lastName: { required },
      businessName: {},
      email: { required, email },
      phone: { required },
      address: {
        details: { required },
        suburb: { required },
        state: { required },
      },
      postcode: { required },
      terms: { sameAsRawValue: sameAs(true) },
      artwork: {},
    };
    // const statesArray = ["ACT", "NSW", "NT", "QLD", "SA", "VIC", "WA"];
    const statesArray: string[] | undefined = inject("statesArray");

    const v$ = useVuelidate(rules, userData.value);
    const onPostcodeSelect = (postcode: any) => {
      const value = parseInt(postcode.target.value);
      if (value < 1000 || typeof value !== "number" || isNaN(value)) {
        return;
      }
      emit("postcodeSelected", value);
    };
    const artworkImages: Ref<{ id: number; url: string }[]> = ref([]);
    const onFilesSelection = function (originalEvent: any) {
      userData.value.artwork = originalEvent.files;
      const urlList = originalEvent.files.map((el: any, index: any) => {
        return { url: el.objectURL, id: index };
      });
      artworkImages.value = urlList;
    };

    const onFilesClear = function () {
      userData.value.artwork = null;
      artworkImages.value = [];
    };
    // const removeImage = (id: number) => {
    //   const newArtworkArray = artworkImages.value.filter((el) => el.id !== id);
    //   userData.value.artwork = newArtworkArray;
    //   artworkImages.value = newArtworkArray;
    // };
    const onSubmit = async () => {
      const result = await v$.value.$validate();
      if (result) {
        emit("submit", userData.value, paymentOption.value.value);
      }
    };
    const spinnerComputed = computed(() => {
      return checkoutFormProcessing.value === true
        ? "pi pi-spin pi-spinner"
        : "";
    });

    return {
      accentColor,
      accentColorHover,

      checkoutFormProcessing,
      paymentOption,
      paymentOptions,
      artworkImages,
      v$,
      baseUrl,
      userData,
      // removeImage,
      onSubmit,
      onFilesSelection,
      onFilesClear,
      statesArray,
      postcodesArray,
      spinnerComputed,
      onPostcodeSelect,
      postcodesLoading,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "517aeb03": (_ctx.accentColor),
  "d22d500e": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__