
import { computed, defineComponent } from "vue";
import { useRouter } from "vue-router";
import { useCompanyStore } from "@/stores/CompanyStore";

export default defineComponent({
  name: "FooterComponent",
  setup() {
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    const addressString = computed(function () {
      let string = "";
      if (companyInfo.address) {
        string += companyInfo.address;
      }

      if (companyInfo.phone) {
        if (string !== "") {
          string += " | ";
        }
        string += companyInfo.phone;
      }

      if (companyInfo.email) {
        if (string !== "") {
          string += " | ";
        }
      }

      return string;
    });
    return {
      baseUrl,
      companyInfo,
      addressString,
    };
  },
});
