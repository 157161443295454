import { computed } from "vue";

export default function forceShowRightLogo(logo: any, cheetaLogo: any) {
  return computed(() => {
    if (logo.frontChest && cheetaLogo.position === "leftChest") {
      return true;
    } else {
      return false;
    }
  });
}
