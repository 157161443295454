<template>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xml:space="preserve"
    xmlns:serif="http://www.serif.com/"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M10.5,20L10.5,7L5,7L5,4L19,4L19,7L13.5,7L13.5,20L10.5,20Z"
      :style="{
        fillRule: 'nonzero',
        fill: active ? '#ffffff' : defaultAccentColor,
      }"
    />
  </svg>
</template>

<script>
import { computed, ref } from "vue";

export default {
  name: "TextIcon",
  props: ["isActive", "element", "accentColor"],
  setup(props) {
    const hover = ref(false);
    const active = computed(
      () => props.isActive === true || props.element.hover === true
    );
    const defaultAccentColor = computed(() =>
      props.accentColor ? props.accentColor : "#ff7434"
    );
    return { active, hover, defaultAccentColor };
  },
};
</script>
