import { computed } from "vue";

export default function useChartsSelector(productType: string | null) {
  return computed(() => {
    switch (productType) {
      case "basketball_jerseys":
        return "Basketball-Singlet-Size-Chart.pdf";
      case "polo":
        return "Polo-Sizing-Chart.pdf";
      case "basketball_shorts":
        return "Basketball-Short-Size-Chart.pdf";
      case "singlet":
        return "Training-Singlet-Sizing-Chart.pdf";
      case "t_shirt":
        return "Tshirt-Sizing-Chart.pdf";
      case "crewsock":
        return "Socks-Sizing-Chart.pdf";
      case "cuthoodie":
      case "subhoodie":
        return "Hoodie-Sizing-Chart.pdf";
      default:
        return null;
    }
  });
}
