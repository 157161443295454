import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { useStorage } from "@vueuse/core";
import type { Ref } from "vue";
import * as _ from "lodash";

import {
  IAddon,
  ICartItem,
  ITextData,
  IEditedProductData,
  IProduct,
  IMultipleLogoObject,
  IPlayerData,
  ISizeData,
  ICheetaLogoMeta,
  IPlayerDataProductDetails,
} from "@/interfaces";

export const useCartStore = defineStore("CartStore", () => {
  const cart = useStorage("CartStore", ref([]) as Ref<Array<ICartItem>>);
  const cartHash = useStorage("CartHash", ref(""));
  const pendingItem = ref({}) as Ref<IProduct>;
  const availableAddons = ref([]) as Ref<IAddon[]>;
  const editedValues = ref(null) as Ref<IEditedProductData | null>;

  // Getters
  const getCart = computed(() => {
    return cart.value;
  });
  const getCartHash = computed(() => {
    return cartHash.value;
  });
  const getPendingItem = computed(() => {
    return pendingItem;
  });
  const getTextData = computed(() => {
    return pendingItem.value.text;
  });
  const getAvailableAddons = computed(() => {
    return availableAddons;
  });
  const getChangableColors = computed(() => {
    // const product = pendingItem.value;
    return {
      primary: {
        primary_1: {
          id: "primary_1",
          color: pendingItem.value.colors.primary_1.value,
          name: pendingItem.value.colors.primary_1.name,
        },
        primary_2: {
          id: "primary_2",
          color: pendingItem.value.colors.primary_2
            ? pendingItem.value.colors.primary_2.value
            : null,
          name: pendingItem.value.colors.primary_2
            ? pendingItem.value.colors.primary_2.name
            : null,
        },
      },
      secondary: {
        secondary_1: {
          id: "secondary_1",
          color: pendingItem.value.colors.secondary_1
            ? pendingItem.value.colors.secondary_1.value
            : null,
          name: pendingItem.value.colors.secondary_1
            ? pendingItem.value.colors.secondary_1.name
            : null,
        },
        secondary_2: {
          id: "secondary_2",
          color: pendingItem.value.colors.secondary_2
            ? pendingItem.value.colors.secondary_2.value
            : null,
          name: pendingItem.value.colors.secondary_2
            ? pendingItem.value.colors.secondary_2.name
            : null,
        },
      },
    };
  });

  // Mutations
  function setCartHash() {
    let hash = 0;
    if (!getCart.value.length) return;
    const cartItemsJson = JSON.stringify(getCart.value);
    for (let i = 0; i < cartItemsJson.length; i++) {
      const char = cartItemsJson.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    const timestamp = Math.floor(Date.now() / 1000);
    cartHash.value = `${timestamp}:${hash}`;
  }
  function saveEditedData() {
    const objectToSave: IEditedProductData = {
      colors: pendingItem.value.colors,
      text: pendingItem.value.text,
      addons: pendingItem.value.addons,
      logo: pendingItem.value.logo,
      playersDetails: pendingItem.value.playersDetails || null,
      details: pendingItem.value.details,
    };
    editedValues.value = objectToSave;
  }
  function changeText(data: ITextData) {
    pendingItem.value.text = data;
    saveEditedData();
  }
  function changeColor(type: string, color: { value: string; name: string }) {
    type ObjectKey = keyof typeof pendingItem.value.colors;
    const key = type as ObjectKey;
    pendingItem.value.colors[key] = color;
    saveEditedData();
  }
  function changeAddons(data: Array<IAddon>) {
    pendingItem.value.addons = data;
    saveEditedData();
  }
  function setPlayerDetails(data: Array<IPlayerData>) {
    pendingItem.value.playersDetails = data;
    saveEditedData();
  }
  function setProductSizes(data: ISizeData[]) {
    pendingItem.value.sizes = data;
    saveEditedData();
  }
  function setPriceToCartItem(price: number, id: string) {
    const item = cart.value.find((product) => product.id === id);
    if (item) item.price = price;
  }
  function setPrice(data: number) {
    pendingItem.value.price = data;
  }
  function setMinQty(qty: number) {
    pendingItem.value.minQty = qty;
  }
  function setLogo(imageData: { path: string; size: number }) {
    pendingItem.value.logo = imageData;
    saveEditedData();
  }
  function setOneOfLogos(data: {
    path: string;
    size: number;
    position: string;
    offsetX: number;
    offsetY: number;
  }) {
    const key = data.position as keyof IMultipleLogoObject;
    // TODO: ANY??
    (pendingItem.value.logo as any)[key] = data;
    saveEditedData();
  }
  function removeOneOfLogos(position: string) {
    const key = position as keyof IMultipleLogoObject;
    (pendingItem.value.logo as any)[key] = null;
    saveEditedData();
  }
  function setAvailableAddons(addons: IAddon[]) {
    availableAddons.value = addons;
  }

  function changeCheetaLogoData(data: ICheetaLogoMeta) {
    if (pendingItem.value.cheetaLogo) {
      pendingItem.value.cheetaLogo = data;
      saveEditedData();
    } else throw new Error('Item "cheetaLogo" property is undefined');
  }
  function changeDetailsOnMultiplePlayersProduct(
    data: IPlayerDataProductDetails
  ) {
    if (pendingItem.value.details) pendingItem.value.details = data;
  }
  function changeQuantity(newQuantity: number, id: string) {
    const product = cart.value.find((el) => el.id === id);
    if (product) product.quantity = newQuantity;
    setCartHash();
  }
  function replaceProduct(item: ICartItem) {
    const indexToReplace = cart.value.findIndex((product) => {
      return product.id === item.id;
    });
    cart.value.splice(indexToReplace, 1, item);
    setCartHash();
  }
  function addProduct(item: ICartItem) {
    cart.value.push(item);
    setCartHash();
  }
  function getProductById(id: string) {
    const product = cart.value.find((product) => product.id === id);
    if (!product) throw new Error(`Product not found`);
    return product;
  }
  function deleteProduct(product: ICartItem) {
    const newArray = cart.value.filter((el) => el !== product);
    cart.value = newArray;
    setCartHash();
  }
  function clearCart() {
    cart.value = [];
    cartHash.value = "";
  }
  function clearEditedValuesBuffer() {
    editedValues.value = null;
  }
  function setPendingItem(product: IProduct) {
    const productCopy: IProduct = _.cloneDeep(product);
    if (editedValues.value) {
      productCopy.addons = [...editedValues.value.addons];
      productCopy.colors = {
        primary_1: editedValues.value.colors.primary_1,
        primary_2: editedValues.value.colors.primary_2,
        secondary_1:
          productCopy.colors.secondary_1 &&
          editedValues.value.colors.secondary_1 !== null
            ? editedValues.value.colors.secondary_1
            : productCopy.colors.secondary_1,
        secondary_2:
          productCopy.colors.secondary_2 &&
          editedValues.value.colors.secondary_2 !== null
            ? editedValues.value.colors.secondary_2
            : productCopy.colors.secondary_2,
      };
      if (productCopy.text && editedValues.value.text) {
        productCopy.text.message = editedValues.value.text.message;
        productCopy.text.color = { ...editedValues.value.text.color };
        productCopy.text.font = editedValues.value.text.font;
      }
      productCopy.logo = { ...editedValues.value.logo };
      productCopy.playersDetails = productCopy.playersDetails
        ? [...(editedValues.value.playersDetails as IPlayerData[])]
        : productCopy.playersDetails;
      if (productCopy.details && editedValues.value.details) {
        productCopy.details = editedValues.value.details;
      }
    }
    pendingItem.value = productCopy;
  }

  return {
    cart,
    pendingItem,
    editedValues,

    setLogo,
    setCartHash,
    setPrice,
    setMinQty,
    setProductSizes,
    setAvailableAddons,
    changeAddons,
    changeText,
    changeColor,
    changeQuantity,
    setPriceToCartItem,
    clearEditedValuesBuffer,
    changeCheetaLogoData,
    changeDetailsOnMultiplePlayersProduct,
    setOneOfLogos,
    removeOneOfLogos,
    setPlayerDetails,

    addProduct,
    deleteProduct,
    replaceProduct,
    getProductById,
    getPendingItem,
    setPendingItem,
    clearCart,

    getCart,
    getCartHash,
    getTextData,
    getChangableColors,
    getAvailableAddons,
  };
});
