import { computed } from "vue";

export default function usePricingTableSelector(productType: string | null) {
  return computed(() => {
    switch (productType) {
      case "truckercap":
        return "TruckersPricing.pdf";
      case "basketball_jerseys":
        return "JerseysPricing.pdf";
      case "terry_bucket_hat":
        return "TerryBucketsPricing.pdf";
      case "t_shirt":
        return "TeesPricing.pdf";
      case "sub_bucket_hat":
        return "SubBucketPricing.pdf";
      case "crewsock":
        return "CrewSocksPricing.pdf";
      case "cut_bucket_hat":
        return "CutSewBucketsPricing.pdf";
      case "beanie":
        return "BeaniesPricing.pdf";
      case "scarf":
        return "ScarvesPricing.pdf";
      default:
        return null;
    }
  });
}
