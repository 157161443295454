
import { IAddon, ICartItem } from "@/interfaces";
import { defineComponent, inject, Ref, computed } from "vue";
import Decimal from "decimal.js";
import { useGtm } from "@gtm-support/vue-gtm";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "OrderDetails",
  components: {},
  emits: ["editDesign"],
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const gtm = useGtm();
    const shippingPrice: Ref<"loading" | number | null> | undefined =
      inject("shippingPriceState");
    if (!shippingPrice) throw new Error("shippingPrice is undefined");
    const orderedItems: Ref<Array<ICartItem>> | undefined =
      inject("orderedItems");
    if (!orderedItems) throw new Error("ordered items is undef");
    const parser = (image: string) => {
      const xmlDoc = new DOMParser().parseFromString(image, "text/html");
      const dom = xmlDoc.childNodes[0] as HTMLElement;
      const parsedImage = dom.getElementsByTagName("body")[0].innerHTML;
      return parsedImage;
    };
    const shippingPriceComputed = computed(() => {
      if (typeof shippingPrice.value !== "number") return shippingPrice.value;
      const price = new Decimal(shippingPrice.value);
      return new Decimal(price.toFixed(2))
        .toNumber()
        .toLocaleString("en-US", { minimumFractionDigits: 2 });
    });
    const getItemPrice = (
      elementPrice: number,
      elementQuantity: number,
      elementAddons: Array<IAddon>
    ) => {
      const addonsPrice = elementAddons.reduce((sum, currentItem) => {
        return sum + currentItem.price;
      }, 0);
      const price = (elementPrice + addonsPrice) * elementQuantity;
      return price;
    };
    const getSubTotalPrice = (display: boolean) => {
      const sum = new Decimal(
        orderedItems.value.reduce((sum, currentItem) => {
          const addonsPrice = currentItem.addons.reduce((sum, currentItem) => {
            return sum + currentItem.price;
          }, 0);
          return sum + (currentItem.price + addonsPrice) * currentItem.quantity;
        }, 0)
      );
      if (display) {
        return new Decimal(sum.toFixed(2))
          .toNumber()
          .toLocaleString("en-US", { minimumFractionDigits: 2 });
      }
      return new Decimal(sum).toFixed(2);
    };
    const gstPrice = computed(() => {
      const subtotal = new Decimal(getSubTotalPrice(false));
      const shipping =
        typeof shippingPrice.value == "number"
          ? new Decimal(shippingPrice.value)
          : 0;
      const gst = Decimal.add(subtotal, shipping).mul(0.1).toFixed(2);
      return gst;
    });
    const fullPrice = computed(() => {
      const subtotal = new Decimal(getSubTotalPrice(false));
      const shipping =
        typeof shippingPrice.value == "number"
          ? new Decimal(shippingPrice.value)
          : 0;
      const gst = gstPrice;
      const fullPrice = subtotal.add(shipping).add(gst.value);
      return new Decimal(fullPrice.toFixed(2))
        .toNumber()
        .toLocaleString("en-US", { minimumFractionDigits: 2 });
    });
    const getLabel = (alias: string) => {
      const label = alias.charAt(0).toUpperCase() + alias.slice(1);
      return label.replaceAll("_", " ");
    };
    const editDesign = (product: ICartItem) => {
      emit("editDesign", product);
    };

    if (gtm && fullPrice.value && orderedItems.value.length) {
      let gtmItems: any = [];
      orderedItems.value.forEach((item, index) => {
        gtmItems.push({
          item_id: item.databaseId,
          item_name: item.name,
          index: index,
          item_brand: "Cheeta",
          item_category: item.type,
          price: item.price,
          quantity: item.quantity,
        });
      });

      gtm.trackEvent({
        event: "begin_checkout",
        category: "checkout",
        action: "view",
        currency: "AUD",
        value: fullPrice.value, // total value of cart ex gst
        items: gtmItems,

        noninteraction: false,
      });
    }

    return {
      parser,
      getLabel,
      gstPrice,
      editDesign,
      fullPrice,
      orderedItems,
      getItemPrice,
      shippingPrice,
      getSubTotalPrice,
      shippingPriceComputed,

      accentColor,
      accentColorHover,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "0b7577f3": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__