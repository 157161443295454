<template>
  <text
    text-anchor="middle"
    :x="xCoordinate"
    :y="`${currentPlayer.playerNumberBackVerticalPosition * 10}px`"
    :style="{
      fontFamily: productDetails.fontFamily,
      fontSize: `${currentPlayer.playerNumberBackSize * 10}px`,
      fill: productDetails.fontColor.value,
    }"
  >
    {{ activePlayer.number }}
  </text>
</template>

<script>
import { computed } from "vue";

export default {
  name: "BackPlayerNumber",
  props: ["x", "activePlayer", "details"],
  setup(props) {
    const xCoordinate = computed(() => (props.x ? props.x : 600));
    const currentPlayer = computed(() => props.activePlayer);
    const productDetails = computed(() => props.details);

    return {
      xCoordinate,
      currentPlayer,
      productDetails,
    };
  },
};
</script>

<style scoped></style>
