import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a786a50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-wrapper" }
const _hoisted_2 = { class: "sidebar" }
const _hoisted_3 = { class: "sidebar-elements-container" }
const _hoisted_4 = ["onClick", "onMouseenter", "onMouseleave"]
const _hoisted_5 = { class: "tab-container" }
const _hoisted_6 = { class: "icon" }
const _hoisted_7 = {
  key: 0,
  class: "widget-item"
}
const _hoisted_8 = { class: "widget-section" }
const _hoisted_9 = { class: "product-info-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleSidebarOptions, (el) => {
          return (_openBlock(), _createElementBlock("div", {
            key: el.id,
            class: _normalizeClass([el.active ? 'active' : '', 'sidebar-element']),
            onClick: ($event: any) => (_ctx.switchTab(el)),
            onMouseenter: ($event: any) => (_ctx.hoverEffect(el)),
            onMouseleave: ($event: any) => (_ctx.hoverEffectOver(el))
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(el.icon), {
                  "is-active": el.active,
                  element: el,
                  "accent-color": _ctx.accentColor
                }, null, 8, ["is-active", "element", "accent-color"]))
              ]),
              _createElementVNode("div", null, _toDisplayString(el.name), 1)
            ])
          ], 42, _hoisted_4))
        }), 128))
      ])
    ]),
    (_ctx.productInfoItems.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createTextVNode(" PRODUCT INFO "),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("ul", _hoisted_9, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productInfoItems, (productInfo, key) => {
                return (_openBlock(), _createElementBlock("li", { key: key }, _toDisplayString(productInfo.name), 1))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}