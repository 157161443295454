export function getFontSizeForWrapped(
  textLength: number,
  font: string | null
): number {
  if (textLength <= 16) {
    if (font === "Adelle") return 358;
    if (font === "ArialRounded") return 385;
    if (font === "Calibri" || font === "College") return 405;
    if (font === "Impact") return 415;
    return 365;
  }
  if (textLength <= 20) {
    if (font === "Adelle") return 283;
    if (font === "ArialRounded") return 310;
    if (font === "Calibri" || font === "College") return 330;
    if (font === "Impact") return 340;
    return 290;
  }
  if (font === "Adelle") return 243;
  if (font === "ArialRounded" || font === "College") return 270;
  if (font === "Calibri") return 290;
  if (font === "Impact") return 300;
  return 250;
}
const deviantFonts = [
  "AdelleBoldItalic",
  "AdelleBoldItalic",
  "Adelle",
  "AdelleBold",
  "AdelleItalic",
  "AdelleLight",
  "AdelleLightItalic",
  "AdelleSemiBold",
  "AdelleSemiBoldItalic",
];
export function isDeviantFont(fontName: string | null): boolean {
  if (!fontName) return false;
  return deviantFonts.includes(fontName);
}
