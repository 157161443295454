
import { defineComponent, inject, computed, Ref } from "vue";
import Button from "primevue/button";
import Decimal from "decimal.js";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "SummaryInfo",
  components: {
    Button,
  },
  setup() {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const total: Ref<number> | undefined = inject("totalPrice");
    const totalToDisplay = computed(() => {
      if (total) {
        return new Decimal(total.value.toFixed(2))
          .toNumber()
          .toLocaleString("en-US", { minimumFractionDigits: 2 });
      }
      return 0;
    });
    return {
      totalToDisplay,
      accentColorHover,
      accentColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "1139a8c8": (_ctx.accentColorHover),
  "3e4114d4": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__