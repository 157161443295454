
import { defineComponent, ref, inject, provide, computed } from "vue";
import type { Ref } from "vue";
import DisclaimerText from "@/components/Common/DisclaimerText.vue";

import RadioButton from "primevue/radiobutton";
import Button from "primevue/button";
import Slider from "primevue/slider";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import SelectButton from "primevue/selectbutton";

import {
  IAddon,
  ICheetaLogoMeta,
  IColorOption,
  IMultipleLogoObject,
} from "@/interfaces";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "LogoTab",
  emits: [
    "addonsChanged",
    "cheetaLogoChanged",
    "setOneOfTheLogos",
    "removeOneOfTheLogos",
  ],
  components: {
    Slider,
    Button,
    Accordion,
    AccordionTab,
    RadioButton,
    SelectButton,
    DisclaimerText,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const logos = ref({
      frontFull: {
        path: "",
        size: 30,
        position: "frontFull",
        offsetX: 0,
        offsetY: 0,
        label: "FULL FRONT LOGO",
        embroidery: false,
      },
      frontChest: {
        path: "",
        size: 10,
        position: "frontChest",
        offsetX: 0,
        offsetY: 0,
        label: "CHEST FRONT LOGO",
        embroidery: false,
      },
      backFull: {
        path: "",
        size: 30,
        position: "backFull",
        offsetX: 0,
        offsetY: 0,
        label: "FULL BACK LOGO",
        embroidery: false,
      },
      backUpper: {
        path: "",
        size: 10,
        position: "backUpper",
        offsetX: 0,
        offsetY: 0,
        label: "UPPER BACK LOGO",
        embroidery: false,
      },
    });
    const cheetaLogoData: Ref<ICheetaLogoMeta> = ref({
      position: "neck",
      color: { value: "#ffffff", name: "White" },
      disabledPositions: [],
    });
    const colorOptions: IColorOption[] | undefined = inject("colorOptions");
    const selectedAddons: Ref<IAddon[]> | undefined = inject("selectedAddons");
    if (selectedAddons === undefined)
      throw new Error(
        "Selected addons in multiple logo component are undefined"
      );
    const activeProductType = inject("activeProductType");
    const providedCheetaLogoData: Ref<ICheetaLogoMeta> | undefined =
      inject("cheetaLogoData");
    if (!providedCheetaLogoData) throw new Error("cheetaLogoData is undefined");
    cheetaLogoData.value = providedCheetaLogoData.value;

    const cheetaLogoPositions = ref([
      { id: "neck", label: "NECK" },
      { id: "leftChest", label: "LEFT CHEST" },
      { id: "rightChest", label: "RIGHT CHEST" },
      // { id: "leftBottom", label: "LEFT BOTTOM" },
      // { id: "rightBottom", label: "RIGHT BOTTOM" },
      { id: "back", label: "BACK" },
    ]);
    const embroideryOptions = [
      { value: true, name: `Embroidery logo (+ 5$ extra)` },
      { value: false, name: "Sublimated logo" },
    ];

    cheetaLogoPositions.value = cheetaLogoPositions.value.filter(
      (el) => !providedCheetaLogoData.value.disabledPositions.includes(el.id)
    );

    const providedLogo: Ref<IMultipleLogoObject> | undefined =
      inject("logoImage");
    if (!providedLogo) throw new Error("Provided logos are undefined");
    Object.keys(logos.value).forEach((key) => {
      const itemKey = key as keyof IMultipleLogoObject;
      const providedPath = providedLogo.value[itemKey]?.path || "";
      const providedSize =
        providedLogo.value[itemKey]?.size || logos.value[itemKey].size;
      const providedEmbroideryStatus =
        providedLogo.value[itemKey]?.embroidery || false;
      const providedX = providedLogo.value[itemKey]?.offsetX || 0;
      const providedY = providedLogo.value[itemKey]?.offsetY || 0;
      logos.value[itemKey].path = providedPath;
      logos.value[itemKey].size = providedSize;
      logos.value[itemKey].embroidery = providedEmbroideryStatus;
      logos.value[itemKey].offsetX = providedX;
      logos.value[itemKey].offsetY = providedY;
    });
    const availableAddons: Ref<IAddon[]> | undefined =
      inject("availableAddons");
    if (!availableAddons) throw new Error("Provided addons are undefined");
    const onLogoUpload = (event: Event, position: string) => {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (!files || !files[0]) return;

      let imageFile = files[0]; // Image
      var reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onloadend = function (e) {
        if (e && e.target) {
          const image = e.target.result as string;
          const logoKey = position as keyof IMultipleLogoObject;
          logos.value[logoKey].path = image;
          emit("setOneOfTheLogos", {
            path: image,
            size: logos.value[logoKey].size,
            position,
            offsetX: logos.value[logoKey].offsetX,
            offsetY: logos.value[logoKey].offsetY,
          });
          if (activeProductType === "cuthoodie") addonsChanged(position);
        }
      };
    };
    const addonsChanged = (position: string) => {
      const aliases = {
        frontFull: "front full logo",
        frontChest: "front chest logo",
        backFull: "back full logo",
        backUpper: "back upper logo",
      };
      const selectedAddonName = aliases[position as keyof object];
      const addon = availableAddons.value.find(
        (el) => el.name === selectedAddonName
      );
      emit("addonsChanged", addon);
    };
    // TODO: REBUILD THIS TEMPORARY ADDONS SOLUTION
    const logoTypeAddonsChanged = (position: string) => {
      const aliases = {
        frontFull: "embroidered front full logo",
        frontChest: "embroidered front chest logo",
        backFull: "embroidered back full logo",
        backUpper: "embroidered back upper logo",
      };
      const selectedAddonName = aliases[position as keyof object];
      const addon = availableAddons.value.find(
        (el) => el.name === selectedAddonName
      );
      if (addon) {
        const isAddonAlreadySelected = selectedAddons.value.includes(addon);
        const logoItem: {
          path: string;
          size: number;
          position: string;
          offsetX: number;
          offsetY: number;
          label: string;
          embroidery: boolean;
        } = logos.value[position as keyof object];
        const isEmbroiderySelected = logoItem.embroidery;
        if (!isAddonAlreadySelected && isEmbroiderySelected) {
          emit("addonsChanged", addon);
        }
        if (isAddonAlreadySelected && !isEmbroiderySelected) {
          emit("addonsChanged", addon);
        }
      }
    };
    const removeLogo = (position: string) => {
      const input = document.getElementById(
        `upload-${position}`
      ) as HTMLInputElement;
      input.value = "";
      const key = position as keyof IMultipleLogoObject;
      logos.value[key].path = "";
      emit("removeOneOfTheLogos", position);
      if (activeProductType === "cuthoodie") addonsChanged(position);
    };
    const onCheetaLogoChange = () => {
      emit("cheetaLogoChanged", cheetaLogoData.value);
    };
    const changeCheetaLogoColor = (color: IColorOption) => {
      cheetaLogoData.value.color = color;
      onCheetaLogoChange();
    };
    const logoSizeChange = (size: number, position: string) => {
      const key = position as keyof IMultipleLogoObject;
      emit("setOneOfTheLogos", {
        path: logos.value[key].path,
        size: size,
        position,
        offsetX: logos.value[key].offsetX,
        offsetY: logos.value[key].offsetY,
      });
    };
    const logoPositionOffsetChanged = (
      newOffset: number,
      offsetAxis: string,
      position: string
    ) => {
      const key = position as keyof IMultipleLogoObject;
      emit("setOneOfTheLogos", {
        path: logos.value[key].path,
        size: logos.value[key].size,
        position,
        offsetX: logos.value[key].offsetX,
        offsetY: logos.value[key].offsetY,
      });
    };
    return {
      baseUrl,
      logos,
      removeLogo,
      onLogoUpload,
      colorOptions,
      cheetaLogoData,
      logoSizeChange,
      availableAddons,
      embroideryOptions,
      activeProductType,
      onCheetaLogoChange,
      cheetaLogoPositions,
      changeCheetaLogoColor,
      logoTypeAddonsChanged,
      logoPositionOffsetChanged,
      accentColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7dd3283a": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__