
import { computed, defineComponent, inject, Ref, ref } from "vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import { IChangableColors, IColorOption, SidebarOption } from "@/interfaces";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "ColorTab",
  emits: ["colorChanged"],
  components: {
    Accordion,
    AccordionTab,
  },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const productType = inject("activeProductType");
    const truckerHatColors: Ref<{
      primary_1: IColorOption[];
      primary_2: IColorOption[];
      secondary_1: IColorOption[];
    }> = ref({
      primary_1: [],
      primary_2: [],
      secondary_1: [],
    });
    if (productType === "truckercap") {
      const hatsColorsOptions:
        | {
            primary_1: IColorOption[];
            primary_2: IColorOption[];
            secondary_1: IColorOption[];
          }
        | undefined = inject("colorOptionsForHats");
      if (!hatsColorsOptions) throw new Error("Hats colors is undefined");
      truckerHatColors.value = hatsColorsOptions;
    }
    const colorOptions: IColorOption[] | undefined = inject("colorOptions");
    const changableColors: Ref<IChangableColors> | undefined =
      inject("changableColors");
    if (!changableColors) throw new Error("Changable colors is undefined");
    const activeSidebarOption: Ref<SidebarOption> | undefined = inject(
      "activeSidebarOption"
    );

    const areSecondaryColorsAvailable = computed(() => {
      return changableColors.value.secondary.secondary_1.color !== null;
    });
    const changeColor = (
      type: string,
      color: { value: string; name: string }
    ) => {
      emit("colorChanged", { type, color });
    };
    return {
      accentColor,
      accentColorHover,
      activeSidebarOption,
      productType,
      changeColor,
      colorOptions,
      changableColors,
      truckerHatColors,
      areSecondaryColorsAvailable,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "61b48e8a": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__