
import { defineComponent, computed, ref, Ref, provide } from "vue";
import router from "@/router";
import { useCartStore } from "@/stores/CartStore";
import { useProductsStore } from "@/stores/ProductsStore";
import { ICartItem } from "@/interfaces";
import Button from "primevue/button";
import { API } from "@/api";

import CartPanel from "@/components/Cart/CartPanel.vue";
import Header from "../components/Header.vue";
import SummaryInfo from "@/components/Cart/SummaryInfo.vue";

import { useGtm } from "@gtm-support/vue-gtm";
import { useCompanyStore } from "@/stores/CompanyStore";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "CartView",
  components: {
    Header,
    SummaryInfo,
    CartPanel,
    Button,
  },
  setup() {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const cart = useCartStore();
    const api = new API();
    const gtm = useGtm();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }

    const orderedItems = computed(() => {
      return cart.getCart;
    });

    const deleteItem = (item: ICartItem) => {
      cart.deleteProduct(item);
    };

    const getTotalPrice = computed(() => {
      if (orderedItems.value.length > 0) {
        return orderedItems.value.reduce((sum, currentItem) => {
          const addonsPrice = currentItem.addons.reduce((sum, currentItem) => {
            return sum + currentItem.price;
          }, 0);
          return sum + (currentItem.price + addonsPrice) * currentItem.quantity;
        }, 0);
      }
      return null;
    });

    if (gtm && getTotalPrice.value && orderedItems.value.length) {
      let gtmItems: any = [];
      orderedItems.value.forEach((item, index) => {
        gtmItems.push({
          item_id: item.databaseId,
          item_name: item.name,
          index: index,
          item_brand: "Cheeta",
          item_category: item.type,
          price: item.price,
          quantity: item.quantity,
        });
      });

      gtm.trackEvent({
        event: "view_cart",
        category: "cart",
        action: "view",
        currency: "AUD",
        value: getTotalPrice.value, // total value of cart ex gst
        items: gtmItems,

        noninteraction: false,
      });
    }

    const editDesign = (product: ICartItem) => {
      router.push({
        name: "constructor",
        params: { product: product.type },
        query: { id: product.id },
      });
    };
    const priceLoading = ref({ loading: false, id: "" });
    const changeQuantity = (qty: number, id: string, dbId: number) => {
      cart.changeQuantity(qty, id);
      fetchActualPrice(dbId, "", qty, id);
    };
    const fetchActualPrice = async (
      dbId: number,
      addons: string,
      quantity: number,
      id: string
    ) => {
      // TODO: REBUILD LOADING STATE AND ASSIGN LOADING STATE TO PRICE IN ORDERED ITEMS
      priceLoading.value.loading = true;
      priceLoading.value.id = id;
      let price = await api.fetchItemPrice(
        companyInfo.id,
        dbId,
        addons,
        quantity
      );
      if (quantity > 0) price = price / quantity;
      cart.setPriceToCartItem(price, id);
      priceLoading.value.loading = false;
    };

    // DATA TO PROVIDE
    provide(
      "orderedItems",
      computed(() => cart.getCart)
    );
    provide("priceLoading", priceLoading);
    provide("totalPrice", getTotalPrice);
    ////////////////

    return {
      editDesign,
      deleteItem,
      orderedItems,
      changeQuantity,
      getTotalPrice,
      accentColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "075e470a": (_ctx.accentColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__