
import { defineComponent, onBeforeUnmount, inject, Ref, computed } from "vue";
import { SidebarOption } from "@/interfaces";
import ColorsIcon from "@/components/SVGImagesAsComponents/Sidebar/ColorsIcon.vue";
import ProgressBarVertical from "@/components/SVGImagesAsComponents/Sidebar/ProgressBarVertical.vue";
import useThemeSettings from "@/hooks/themeSettings";
import useProductInfo from "@/hooks/productInfo";

const __default__ = defineComponent({
  name: "ToolsSidebar",
  emits: ["tabChanged"],
  components: { ProgressBarVertical, ColorsIcon },
  setup(props, { emit }) {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;

    const activeProductType: string | undefined = inject("activeProductType");
    if (!activeProductType)
      throw new Error("Active product in text component undefined");
    const productInfoItems = useProductInfo(activeProductType);

    const sidebarOptions: Ref<Array<SidebarOption>> | undefined =
      inject("sidebarOptions");
    if (!sidebarOptions) throw new Error("Sidebar options are undefined");
    const switchTab = (el: object) => {
      emit("tabChanged", el);
    };
    onBeforeUnmount(() => {
      emit("tabChanged", sidebarOptions.value[0]);
    });

    const hoverEffect = (item: SidebarOption) => {
      item.hover = true;
    };

    const hoverEffectOver = (item: SidebarOption) => {
      item.hover = false;
    };

    const visibleSidebarOptions = computed(() => {
      return sidebarOptions.value.filter(
        (sidebarOption: SidebarOption) => sidebarOption.visible
      );
    });

    return {
      accentColor,
      accentColorHover,
      hoverEffect,
      hoverEffectOver,
      visibleSidebarOptions,
      switchTab,
      productInfoItems,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "48ad9fb2": (_ctx.accentColor),
  "bc318eac": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__