<template>
  <text
    :style="{
      fontFamily: details.fontFamily,
      fontSize: `${details.teamNameSize * 10}px`,
      fill: details.fontColor.value,
    }"
  >
    <textPath xlink:href="#curve" startOffset="50%" text-anchor="middle">
      {{ details.teamName }}
    </textPath>
  </text>
</template>

<script>
export default {
  name: "CurvedTeamName",
  props: ["details"],
};
</script>

<style scoped></style>
