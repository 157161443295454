
import { API } from "@/api";
import { defineComponent, ref, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { IOrderedItem } from "@/interfaces";
import { useCompanyStore } from "@/stores/CompanyStore";

export default defineComponent({
  name: "PDFBlueprint",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const orderId = route.query.id as string;
    const itemId = route.query.itemID ? (route.query.itemID as string) : null;
    const orderMeta = ref({
      date: null,
      id: null,
    });
    if (!orderId) router.push({ name: "home" });
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }

    const api = new API();
    const orderedItems: Ref<IOrderedItem[]> = ref([]);
    api.getOrder(companyInfo.id, orderId, itemId).then((response) => {
      orderMeta.value = {
        id: response.data.data.alias,
        date: response.data.data.createdAt,
      };
      orderedItems.value = assignOrderedItems(response.data.data.items);
    });
    const assignOrderedItems = (orderedItems: IOrderedItem[]) => {
      // const productsWithPlayersData = [
      //   "t_shirt",
      //   "longsleeve",
      //   "cuthoodie",
      //   "subhoodie",
      //   "polo",
      //   "singlet",
      //   "basketball_jerseys",
      // ];
      return orderedItems.map((el: any): IOrderedItem => {
        // if (productsWithPlayersData.includes(el.categoryAlias)) {
        //   el.type = "PlayersDataProduct";
        //   el.logo = null;
        //   el.logos = {
        //     logoBackCenter: el.logoBackCenter.image ? el.logoBackCenter : null,
        //     logoBackFull: el.logoBackFull.image ? el.logoBackFull : null,
        //     logoBackUpper: el.logoBackUpper.image ? el.logoBackUpper : null,
        //     logoFrontCenter: el.logoFrontCenter.image
        //       ? el.logoFrontCenter
        //       : null,
        //     logoFrontChest: el.logoFrontChest.image ? el.logoFrontChest : null,
        //     logoFrontFull: el.logoFrontFull.image ? el.logoFrontFull : null,
        //   };
        // } else {
        //   el.type = "Basic";
        //   el.logos = null;
        //   el.logo = el.logo.image ? el.logo : null;
        // }

        //
        if (
          el.logoBackCenter.image ||
          el.logoBackFull.image ||
          el.logoBackUpper.image ||
          el.logoFrontCenter.image ||
          el.logoFrontChest.image ||
          el.logoFrontFull.image
        ) {
          el.logos = {
            logoBackCenter: el.logoBackCenter.image ? el.logoBackCenter : null,
            logoBackFull: el.logoBackFull.image ? el.logoBackFull : null,
            logoBackUpper: el.logoBackUpper.image ? el.logoBackUpper : null,
            logoFrontCenter: el.logoFrontCenter.image
              ? el.logoFrontCenter
              : null,
            logoFrontChest: el.logoFrontChest.image ? el.logoFrontChest : null,
            logoFrontFull: el.logoFrontFull.image ? el.logoFrontFull : null,
          };
        } else {
          el.logos = null;
        }

        el.logo = el.logo.image ? el.logo : null;
        //

        el.details = JSON.parse(el.details);
        return el;
      });
    };

    const downloadImages = async (item: IOrderedItem) => {
      await api.downloadProductAssets(orderId, companyInfo.id);
    };

    const handleLogoClick = function () {
      if (companyInfo.website) {
        window.location.href = companyInfo.website;
      }
      // window.location.href =
      //   "https://cheetateamwear.com.au/pages/design-your-own";
    };
    return {
      orderMeta,
      orderedItems,
      downloadImages,
      handleLogoClick,
    };
  },
});
