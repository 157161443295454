import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import ProductsView from "../views/ProductsView.vue";
import ConstructorView from "../views/ConstructorView.vue";
import RelatedItems from "../views/RelatedItems.vue";
import CartView from "../views/CartView.vue";
import CheckoutView from "../views/CheckoutView.vue";
import Blueprint from "../views/BlueprintView.vue";
import PaymentSuccess from "../views/SuccessView.vue";
import PaymentFailed from "../views/PaymentFailedView.vue";
import PageNotFound from "../views/PageNotFoundView.vue";
import TermsAndConditionView from "@/views/TermsAndConditionView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import CompanyNotFoundView from "@/views/CompanyNotFoundView.vue";
import { useCompanyStore } from "@/stores/CompanyStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: ProductsView,
    meta: {
      title: "Design Your Own Custom Sportswear Online",
      description:
        "Design your own custom sportswear with ease. Personalise jerseys, hoodies, and more with Cheeta Teamwear.",
    },
  },
  {
    path: "/designer/:product",
    name: "constructor",
    component: ConstructorView,
    meta: { title: "Designer - Cheeta Teamwear" },
    children: [
      { path: "/designer/:product/design/:hash", component: ConstructorView },
    ],
  },
  {
    path: "/related-items",
    name: "related-items",
    component: RelatedItems,
    meta: { title: "Suggested Products - Cheeta Teamwear" },
  },
  {
    path: "/cart",
    name: "cart",
    component: CartView,
    meta: { title: "Cart - Cheeta Teamwear" },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutView,
    meta: { title: "Checkout - Cheeta Teamwear" },
  },
  {
    path: "/blueprint",
    name: "blueprint",
    component: Blueprint,
    meta: { title: "" },
  },
  {
    path: "/success",
    name: "order-success",
    component: PaymentSuccess,
    meta: { title: "" },
    beforeEnter: (to, from, next) => {
      if (!to.query.order_id) next({ name: "home" });
      else next();
    },
  },
  {
    path: "/order-failed",
    name: "order-failed",
    component: PaymentFailed,
    meta: { title: "" },
  },
  // {
  //   path: "/terms-and-conditions",
  //   name: "terms-and-conditions",
  //   component: TermsAndConditionView,
  //   meta: { title: "Terms and Conditions - Cheeta Teamwear" },
  // },
  // {
  //   path: "/privacy-policy",
  //   name: "privacy-policy",
  //   component: PrivacyPolicyView,
  //   meta: { title: "Privacy Policy - Cheeta Teamwear" },
  // },
  {
    path: "/company-not-found",
    name: "company-not-found",
    component: CompanyNotFoundView,
  },
  {
    path: "/:notFound",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  const companyStore = useCompanyStore();
  const companyInfo = companyStore.getCompanyInfo;

  if (to.name !== "company-not-found" && companyInfo === null) {
    try {
      const companyStore = useCompanyStore();
      const urlInfo = new URL(window.location.href);
      await companyStore.retrieveCompanyInfo(urlInfo.hostname);
    } catch (e) {
      router.push({ name: "company-not-found" });
    }
  }

  if (typeof to.name === "string") {
    if (to.name !== "constructor") {
      const title: string = to.meta.title as string;
      document.title = title ? title : "Cheeta Teamwear";

      const description: string | undefined = to.meta.description as string;
      if (description) {
        const descriptionMetaTag = document.querySelector(
          "meta[name='description']"
        );
        if (descriptionMetaTag) {
          descriptionMetaTag.setAttribute("content", description);
        }
      }
    }
  }
  next();
});

export default router;
