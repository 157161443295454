import axios from "axios";
import { IFetchedUpsellItem, IProductDescription } from "./interfaces";

export class API {
  async getCompanyInfo(domain: string) {
    return await axios.get(`company/find?domain=${domain}`);
  }
  async getUpsellItems(
    companyId: number,
    ids: Array<number>
  ): Promise<Array<IProductDescription> | []> {
    try {
      const response = await axios.get(`/company/${companyId}/upsell-items`, {
        params: { ids: ids.map((el) => el.toString()).join(",") },
      });
      return response.data.data;
    } catch (e) {
      return [];
    }
  }
  async fetchItemPrice(
    companyId: number,
    productId: number,
    addons: string,
    quantity: number
  ) {
    const response = await axios.get(
      `/company/${companyId}/prices/product/${productId}`,
      {
        params: {
          addons: addons.length > 0 ? addons : null,
          quantity: quantity ? quantity : null,
        },
      }
    );
    return response.data.price;
  }
  async checkout(companyId: number, formData: FormData) {
    const response = await axios.post(
      `/company/${companyId}/checkout`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  }
  catch(e: Error) {
    console.log(e);
  }
  async getShippingPrice(
    companyId: number,
    postcode: number,
    quantity: number
  ) {
    const response = await axios.get(`company/${companyId}/prices/shipping`, {
      params: { postcode, quantity },
    });
    return response.data.price;
  }
  async getStates() {
    const response = await axios.get("/addresses/states");
    return response;
  }
  async getPostcodes() {
    const response = await axios.get("/addresses/postcodes");
    return response;
  }
  async getOrder(
    companyId: number,
    orderId: string,
    orderItemId?: string | null
  ) {
    let urlString = `company/${companyId}/orders/${orderId}`;
    if (orderItemId) {
      urlString += `?itemID=${orderItemId}`;
    }
    const response = await axios.get(urlString);
    return response;
  }
  async getProductsMeta(companyId: number) {
    const response = await axios.get(`company/${companyId}/products/types`);
    return response.data;
  }
  async downloadProductAssets(orderId: string, companyId: number) {
    await axios({
      url: `company/${companyId}/orders/${orderId}/assets`,
      method: "POST",
      responseType: "blob",
    }).then((response) => this.downloadBlob(response.data));
  }
  async downloadBlob(blob: any, name = "images.zip") {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = blobUrl;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  async getProductsInfo(companyId: number) {
    // TODO: FETCH QUNATITIES AND DB IDs and MAP THEM ON MY PRODUTS DATA
    const response = await axios.get(`/company/${companyId}/products`);
    return response;
  }
  async saveProductDesign(
    companyId: number,
    productID: number,
    formData: FormData
  ) {
    const response = await axios.post(
      `company/${companyId}/products/${productID}/design`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  }
  async getProductDesign(companyId: number, designId: string) {
    const response = await axios.get(`company/${companyId}/design/${designId}`);
    return response;
  }
}
