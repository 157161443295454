import { email, required } from "@vuelidate/validators";
import { computed, Ref, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";

export default function useProductDesign(activeProduct: any, emit: any) {
  const loadingDesignRequest = ref(false);
  const openSaveDesignDialog = ref(false);
  const rules = {
    name: { required, max: 255 },
    lastName: { required, max: 255 },
    email: { required, email },
  };
  const userData = ref({
    email: "",
    name: "",
    lastName: "",
  });
  const v$ = useVuelidate(rules, userData.value);

  const spinnerComputed = computed(() => {
    return loadingDesignRequest.value === true ? "pi pi-spin pi-spinner" : "";
  });

  const onSuccess = (
    userData: any,
    v$: any,
    loadingDesignRequest: Ref<boolean>,
    openSaveDesignDialog: Ref<boolean>
  ) => {
    loadingDesignRequest.value = false;
    openSaveDesignDialog.value = false;
    userData.value.email = "";
    v$.value.$reset();
  };

  const saveDesign = async () => {
    const result = await v$.value.$validate();
    if (result) {
      loadingDesignRequest.value = true;

      await emit(
        "designSaved",
        activeProduct,
        userData,
        v$,
        loadingDesignRequest,
        openSaveDesignDialog,
        onSuccess
      );
    }
  };
  return {
    v$,
    userData,
    loadingDesignRequest,
    openSaveDesignDialog,
    saveDesign,
    spinnerComputed,
  };
}
