
import { defineComponent, computed, ref } from "vue";
import { useRouter } from "vue-router";
import type { Ref } from "vue";

import Header from "../components/Header.vue";
import { API } from "@/api";
import { IProductDescription } from "@/interfaces";
import { useCartStore } from "@/stores/CartStore";
import Skeleton from "primevue/skeleton";

import Button from "primevue/button";
import { useCompanyStore } from "@/stores/CompanyStore";
import useThemeSettings from "@/hooks/themeSettings";

const __default__ = defineComponent({
  name: "RelatedView",
  components: {
    Header,
    Button,
    Skeleton,
  },
  setup() {
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const cart = useCartStore();
    const router = useRouter();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    if (!companyInfo) {
      throw new Error("Company not found");
    }

    const loading = ref(true);

    const itemsRange: IProductDescription[] = [];

    // const itemsRange: IProductDescription[] = [
    //   {
    //     label: "Beanies",
    //     alias: "Beanies",
    //     addons: [],
    //     id: "beanie",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Scarves",
    //     alias: "Scarves",
    //     addons: [],
    //     id: "scarf",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Socks",
    //     alias: "Socks",
    //     addons: [],
    //     id: "crewsock",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Cut & Sew Hoodies",
    //     alias: "Cut & Sew Hoodies",
    //     addons: [],
    //     id: "cuthoodie",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Sublimated Hoodies",
    //     alias: "Sublimated Hoodies",
    //     addons: [],
    //     id: "subhoodie",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Sublimated T-Shirts",
    //     alias: "Sublimated T-Shirts",
    //     addons: [],
    //     id: "t_shirt",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Singlets",
    //     alias: "Singlets",
    //     addons: [],
    //     id: "singlet",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Long-sleeve T-Shirts",
    //     alias: "Long-sleeve T-Shirts",
    //     addons: [],
    //     id: "longsleeve",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Polo-Shirts",
    //     alias: "Polo Shirts",
    //     addons: [],
    //     id: "polo",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Trucker Caps",
    //     alias: "Trucker Caps",
    //     addons: [],
    //     id: "truckercap",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Basketball Shorts",
    //     alias: "Basketball Shorts",
    //     addons: [],
    //     id: "basketball_shorts",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    //   {
    //     label: "Basketball Jerseys",
    //     alias: "Basketball Jerseys",
    //     addons: [],
    //     id: "basketball_jerseys",
    //     price: 0,
    //     colors: null,
    //     minQty: 1,
    //     sizes: [],
    //     settings: { productionTime: null },
    //   },
    // ];

    const orderedItems = computed(() => {
      return cart.getCart;
    });

    const relatedItems: Ref<IProductDescription[]> = ref([]);
    const api = new API();
    const itemsInCartId = orderedItems.value.map((el) => el.databaseId);
    api.getUpsellItems(companyInfo.id, itemsInCartId).then((resp) => {
      if (!resp.length) {
        router.push({ name: "cart" });
      } else {
        resp.map((el) => {
          // const associatedItem = itemsRange.find(
          //   (item) => el.name === item.alias
          // );
          // if (!associatedItem) return;
          // associatedItem.price = el.minPrice;
          // associatedItem.minQuantity = el.minQuantity;
          relatedItems.value.push(el);
        });

        // const labelsArray: string[] = [];
        // resp.forEach((el: IFetchedUpsellItem) => {
        //   labelsArray.push(el.name);
        // });
        // relatedItems.value = itemsRange.filter((el) =>
        //   labelsArray.includes(el.label)
        // );
        loading.value = false;
      }
    });

    const goToEdit = (product: string) => {
      router.push({
        name: "constructor",
        params: { product: product },
      });
    };

    return {
      loading,
      relatedItems,
      goToEdit,
      itemsRange,
      accentColor,
      accentColorHover,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "c010e05c": (_ctx.accentColor),
  "31ee5c8a": (_ctx.accentColorHover)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__