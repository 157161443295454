
import { computed, defineComponent, ref, Ref } from "vue";
import useThemeSettings from "@/hooks/themeSettings";
import { useCompanyStore } from "@/stores/CompanyStore";
import { useRoute, useRouter } from "vue-router";
import Button from "primevue/button";

const __default__ = defineComponent({
  name: "HeaderComponent",
  props: ["category"],
  components: {
    Button,
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const companyStore = useCompanyStore();
    const companyInfo: any = companyStore.getCompanyInfo;
    const baseUrl = computed(() => process.env.VUE_APP_BASE_URI);
    const themeSettings = useThemeSettings();
    const accentColor = themeSettings.getAccentColor;
    const accentColorHover = themeSettings.getAccentColorHover;
    const getCategoryName = computed(() => props.category);
    const handleLogoClick = function () {
      if (companyInfo.website) {
        window.location.href = companyInfo.website;
      }
      // window.location.href =
      //   "https://cheetateamwear.com.au/pages/design-your-own";

      // if (route.name === "home") {
      //   window.open("https://cheetateamwear.com.au/pages/design-your-own");
      // } else {
      //   router.push({ name: "home" });
      // }
    };

    const isBannerVisible: Ref<boolean> = ref(true);
    const closeBanner = function () {
      isBannerVisible.value = false;
    };
    const bannerBtnClick = function () {
      window.location.href = companyInfo.announcementBar.btnUrl;
    };
    const bannerBgColor = computed(() => {
      return companyInfo.announcementBar && companyInfo.announcementBar.bgColor
        ? companyInfo.announcementBar.bgColor
        : "#ffffff";
    });
    const bannerTextColor = computed(() => {
      return companyInfo.announcementBar &&
        companyInfo.announcementBar.bannerTextColor
        ? companyInfo.announcementBar.bannerTextColor
        : "#000000";
    });
    return {
      baseUrl,
      accentColor,
      accentColorHover,
      getCategoryName,
      companyInfo,
      handleLogoClick,
      isBannerVisible,
      closeBanner,
      bannerBtnClick,
      bannerBgColor,
      bannerTextColor,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "70b6d558": (_ctx.accentColor),
  "b8c8ca78": (_ctx.accentColorHover),
  "a233939e": (_ctx.bannerBgColor),
  "252f9589": (_ctx.bannerTextColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__